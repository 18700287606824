<template>
    <div class="progress-tooltip-row">
        <div>
            <b-icon-check-circle v-if="segmentValue.status === 'Finished' || segmentValue.status === 'success' || overrideNotStarted" class="check-circle" variant="success"></b-icon-check-circle>
            <b-spinner v-if="segmentValue.status === 'InProgress' || segmentValue.status === 'primary' " variant="primary" small></b-spinner>
            <b-icon-circle-fill v-if="(segmentValue.status === 'NotStarted' || segmentValue.status === 'not_started') && !overrideNotStarted" class="dot"></b-icon-circle-fill>
            <b-icon-x-circle-fill :id="'stopped-tooltip' + rowCount" v-if="!type && (segmentValue.status === 'Stopped' || segmentValue.status === 'stopped')" class="exclamation-circle" variant="danger"></b-icon-x-circle-fill>
            <b-icon-exclamation-circle-fill :id="'danger-tooltip' + rowCount" v-if="!type && (segmentValue.status === 'Failed' || segmentValue.status === 'danger')" class="exclamation-circle" variant="danger"></b-icon-exclamation-circle-fill>
            <b-icon-exclamation-circle-fill :id="'warning-tooltip' + rowCount"  v-if="type === 'segment' && (segmentValue.status === 'Failed' || segmentValue.status === 'danger')" class="exclamation-circle" variant="warning"></b-icon-exclamation-circle-fill>
            <b-tooltip custom-class="danger-tooltip-wrapper" triggers="click" :target="'danger-tooltip' + rowCount" placement="bottom">
                <div class="danger-tooltip">
                    <div class="flex column">
                        <span>Error</span>
                        <span class="tootltip-title">{{segmentValue.message}}</span>
                        <div class="correlation-wrapper">
                            <span>CorrelationId: {{segmentValue.correlationId}}</span>
                            <b-icon-files class="copy-button" v-on:click="copyToClipboard()"></b-icon-files>
                        </div>
                    </div>
                    <b-icon-x v-b-tooltip.hover="'Copy to clipboard'" class="header-cancel-button" v-on:click="$root.$emit('bv::hide::tooltip', 'danger-tooltip' + rowCount)" variant="secondary"></b-icon-x>
                </div>
             </b-tooltip>
            <b-tooltip custom-class="danger-tooltip-wrapper" triggers="click" :target="'warning-tooltip' + rowCount" placement="bottom">
                <div class="danger-tooltip" >
                    <div class="flex column">
                        <span>Error</span>
                        <span class="tootltip-title">{{segmentValue.message}}</span>
                        <div class="correlation-wrapper">
                            <span>CorrelationId: {{segmentValue.correlationId}}</span>
                            <b-icon-files v-b-tooltip.hover="'Copy to clipboard'" class="copy-button" v-on:click="copyToClipboard()"></b-icon-files>
                        </div>
                    </div>
                    <b-icon-x class="header-cancel-button" v-on:click="$root.$emit('bv::hide::tooltip', 'warning-tooltip' + rowCount)" variant="secondary"></b-icon-x>
                </div>
            </b-tooltip>

            <span class="row-text">{{title}}</span>
        </div>
        <span>{{latency}}</span>
    </div>
</template>

<script>
    import * as moment from 'moment';
    import * as momentDurationFormatSetup from "moment-duration-format";
    export default {
        name: "VideoProcessingVisualizationRow",
        props: {
            rowCount: String,
            title: String,
            type: String,
            segmentValue: {
                status: String,
                latency: String,
            },
            overrideNotStarted: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
               interval: null,
               latency: '',
            }
        },
        methods: {
            copyToClipboard() {
                const el = document.createElement('textarea');
                el.value = this.segmentValue.correlationId;
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
            },
            mapLatency() {
                clearInterval(this.interval);
                if(!this.segmentValue.endTime) {
                    this.interval = setInterval(() => {
                        this.latency =  moment.duration(moment().diff(moment.utc(this.segmentValue.startTime), 's'),'seconds').format("h[h] mm[m] ss[s]");
                    }, 1000);
                    this.latency =  moment.duration(moment().diff(moment.utc(this.segmentValue.startTime), 's'),'seconds').format("h[h] mm[m] ss[s]");
                } else {
                    let duration = 0;
                    if(this.segmentValue.latency){
                        duration = moment.duration(this.segmentValue.latency, 'milliseconds');
                    } else {
                       duration = moment.utc(this.segmentValue.endTime).diff(moment.utc(this.segmentValue.startTime), 'milliseconds');
                    }
                    let format = 'h[h] mm[m] ss[s]';
                    if(duration < 1000){
                        format = 'ss[s] SSS[ms]'
                    }
                    this.latency = moment.duration(duration, 'milliseconds').format(format);
                }
            },
            loadData() {
                this.latency = this.segmentValue.latency;
                this.mapLatency()
            }
        },
        watch: {
          segmentValue: function(newValue, oldValue) {
              if(oldValue) {
                 this.loadData();
              }
          }
        },
        beforeMount(){
            momentDurationFormatSetup(moment);
            this.loadData();
        },
        beforeDestroy(){
            clearInterval(this.interval);
        }
    }
</script>

<style lang="scss">
    .danger-tooltip-wrapper{

        opacity: 1!important;
        .arrow::before {
            border-bottom-color: var(--primary-color) !important;
        }
        .tooltip-inner{
            background-color: rgba(33,44,56, 1) !important;
            border-radius: 5px;
            border: var(--primary-color) solid 2px;
        }


    }
    .exclamation-circle{
        margin-left: 2px;
        cursor: pointer;
    }
    .danger-tooltip {
        display: flex;
        flex-direction: row;
        text-align: left;
        margin: 5px;
        .tootltip-title{
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .copy-button{
            margin-left: 5px;
            cursor: pointer;
        }

        .copy-button:hover{
            color: var(--primary-color);
        }

        .correlation-wrapper{
            justify-content: space-between;
            display: flex;
            align-items: center;
        }

        .header-cancel-button{
            color: #CED6E0!important;
            font-size: 14px;
            border-radius: 5px;
            cursor: pointer;
        }
        .header-cancel-button:hover{
            background-color: white;
            color: #4B5561!important;
        }
    }
</style>

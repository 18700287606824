import axios from 'axios';

export default {
	state: {
		data: {
			analyzedVideos: {},
			analyzeRequestsSubscriptions: {}
		},
		databricksDetails: {
			address: '',
			port: '',
			orgId: '',
			apiToken: '',
			clusterId: '',
			clusterType: 'cpu'
		},
		aadDetails: {
			clientId: '',
			tenantId: '',
			clientSecret: ''
		},
		storageDetails: {
			storageAccount: '',
			storageKey: ''
		},
		mode: 'normal',
		isAadEnabled: true,
		showLoginForm: false,
		isPreviewMode() {
			return this.mode === 'preview';
		}
	},
	mutations: {
		SET_DATABRICKS_DETAILS(state, data) {
			state.databricksDetails.address = data.address;
			state.databricksDetails.port = data.port;
			state.databricksDetails.orgId = data.orgId;
			state.databricksDetails.clusterId = data.clusterId;
			state.databricksDetails.clusterType = data.clusterType;
		},
		SET_AAD_DETAILS(state, data) {
			state.aadDetails.clientId = data.clientId;
			state.aadDetails.tenantId = data.tenantId;
		},
		SET_STORAGE_DETAILS(state, storageAccount) {
			state.storageDetails.storageAccount = storageAccount;
		},
		SET_MODE(state, mode) {
			state.mode = mode;
		},
		SET_ADD_ENABLED(state, isAadEnabled) {
			state.isAadEnabled = isAadEnabled;
			if(!isAadEnabled) {
				state.showLoginForm = true;
			}
		},
		SET_SHOW_LOGIN_FORM(state, showLoginForm) {
			state.showLoginForm = showLoginForm;
		},
		TOGGLE_SHOW_LOGIN_FORM(state) {
			state.showLoginForm = !state.showLoginForm;
		}
	},
	actions: {
		getMode(context) {
			return axios
				.get(`${process.env.VUE_APP_BASE_URL}previewmode`)
				.then(response => {
					if (response && response.data) context.commit('SET_MODE', 'preview');
					return response.data
				})
				.catch(err => {
					let errMessage = err && err.response && err.response.data ? err.response.data.message : null;
					let type = 1;
					context.commit('SHOW_MESSAGE', {
						type: type,
						message: errMessage,
						error: err
					});
				});
		},

		getAddEnabled(context) {
			return axios
				.get(`${process.env.VUE_APP_BASE_URL}configurations/azure-active-directory-enabled`)
				.then(response => { context.commit('SET_ADD_ENABLED', response.data);})
				.catch(err => {
					let errMessage = err && err.response && err.response.data ? err.response.data.message : null;
					let type = 1;
					context.commit('SHOW_MESSAGE', {
						type: type,
						message: errMessage,
						error: err
					});
				});
		},

		getBlobStorageDetails(context) {
			axios
				.get(`${process.env.VUE_APP_BASE_URL}configurations/azure-storage`)
				.then(response => {
					if (response && response.status === 200) {
						context.commit('SET_STORAGE_DETAILS', response.data.storageAccount);
						context.commit('SET_BLOB_DETAILS', response.data.storageAccount);
					}
				})
				.catch(err => {
					let errMessage = err && err.response && err.response.data ? err.response.data.message : null;
					let type = 1;
					if (errMessage && errMessage === 'Azure storage configuration not be found, please set one up.') {
						type = 2;
					}
					context.commit('SHOW_MESSAGE', {
						type: type,
						message: errMessage,
						error: err
					});
				});
		},

		updateBlobStorageDetails(context, d) {
			axios
				.post(`${process.env.VUE_APP_BASE_URL}configurations/azure-storage`, d.data)
				.then(response => {
					if (response && response.status === 200) {
						context.commit('SHOW_MESSAGE', {
							type: 3,
							message: 'Blob Storage details updated successfully'
						});
						context.commit('SET_STORAGE_DETAILS', d.data.storageAccount);
						context.commit('SET_BLOB_DETAILS', d.data.storageAccount);
						d.successCallback();
					}
				})
				.catch(err => {
					context.commit('SHOW_MESSAGE', {
						type: 1,
						message: err && err.response && err.response.data ? err.response.data.message : null,
						error: err
					});
				});
		},

		getDatabricksConfigDetails(context, callback) {
			axios
				.get(`${process.env.VUE_APP_BASE_URL}configurations/databricks`)
				.then(response => {
					if (response && response.status === 200) {
						context.commit('SET_DATABRICKS_DETAILS', response.data);
					}
				})
				.catch(err => {
					let errMessage = err && err.response && err.response.data ? err.response.data.message : null;
					let type = 1;
					if (errMessage && errMessage === 'Databricks configuration not found, please set one up.') {
						type = 2;
					}
					context.commit('SHOW_MESSAGE', {
						type: type,
						message: errMessage,
						error: err
					});
				});
		},

		updateDatabricksDetails(context, d) {
			axios
				.post(`${process.env.VUE_APP_BASE_URL}configurations/databricks`, d.data)
				.then(response => {
					if (response && response.status === 200) {
						context.commit('SHOW_MESSAGE', {
							type: 3,
							message: 'Databricks Account Info has been saved. This will configure your Databricks cluster to work with Nash. It will start the cluster if it is currently stopped and change its configuration accordingly.'
						});
						d.successCallback();
					}
				})
				.catch(err => {
					context.commit('SHOW_MESSAGE', {
						type: 1,
						message: err && err.response && err.response.data ? err.response.data.message : null,
						error: err
					});
				});
		},

		getAzureADConfigDetails(context, callback) {
			axios
				.get(`${process.env.VUE_APP_BASE_URL}configurations/azure-active-directory`)
				.then(response => {
					if (response && response.status === 200) {
						context.commit('SET_AAD_DETAILS', response.data);
					}
				})
				.catch(err => {
					let errMessage = err && err.response && err.response.data ? err.response.data.message : null;
					let type = 1;
					if (errMessage && errMessage === 'Azure Active Directory configuration not found, please set one up.') {
						type = 2;
					}
					context.commit('SHOW_MESSAGE', {
						type: type,
						message: errMessage,
						error: err
					});
				});
		},

		updateAzureADDetails(context, d) {
			axios
				.post(`${process.env.VUE_APP_BASE_URL}configurations/azure-active-directory`, d.data)
				.then(response => {
					if (response && response.status === 200) {
						context.commit('SHOW_MESSAGE', {
							type: 3,
							message: 'Azure AD Settings saved successfully'
						});
						d.successCallback();
					}
				})
				.catch(err => {
					context.commit('SHOW_MESSAGE', {
						type: 1,
						message: err && err.response && err.response.data ? err.response.data.message : null,
						error: err
					});
				});
		}
	}
};

import axios from 'axios';
import store from '../store/index';
import router from "../router";


export default function setup() {
    let req = [];
    axios.interceptors.request.use(function (config) {
        req.push(true);
        store.state.showLoader = true;
        config.withCredentials = true;
        return config;
    }, function (err) {
        return Promise.reject(err);
    });

    axios.interceptors.response.use((response) => {
        req.pop();
        if (req.length === 0)
            store.state.showLoader = false;
        return response;
    }, function (error) {
        req.pop();
        if (req.length === 0)
            store.state.showLoader = false;
        if (error && error.response && (error.response.status === 401 || (error.response.status === 403 && error.response.config.url === `${process.env.VUE_APP_BASE_URL}principal`))) {
            store.state.users.currentUser = null;
            if(router.currentRoute.path !== '/login')
            router.push({ path: '/login', query: {unauthorized: true} });
        }
        return Promise.reject(error)
    })
}

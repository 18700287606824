import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios'
import VueAxios from 'vue-axios'
import events from './modules/events';
import users from './modules/users';
import azureBlobData from './modules/azureBlobData';
import pipelineConfigs from './modules/pipelineConfigs';
import analyzedVideos from "./modules/analyzedVideos";
import manageAoi from './modules/manageAoi';
import similarity from './modules/similarity';
import anomaly from './modules/anomaly';
import settings from './modules/settings';
import websocket from './modules/websocket';
import linechart from './modules/linechart';
import videoPlayer from './modules/videoPlayer';

Vue.use(Vuex);
Vue.use(VueAxios, axios);

export default new Vuex.Store({
  modules: {
    events,
    users,
    azureBlobData,
    pipelineConfigs,
    manageAoi,
    websocket,
    similarity,
    anomaly,
    settings,
    linechart,
    videoPlayer,
    analyzedVideos
  },
  state: {
    message: {},
    showLoader: false,
  },
  mutations: {
    SHOW_MESSAGE(state, val) {
      let errMessage = val.message;
      if(!errMessage && val.error) {
        if(val.error.response && val.error.response.data){
          errMessage = val.error.response.data;
          console.log(val.error.response)
        }
        if(!errMessage && val.error.message){
          errMessage = val.error.message
        }
      }

      if (errMessage !== 'Full authentication is required to access this resource') {
        state.message = {
          type: val.type,
          message: errMessage,
          errorDelay: val.errorDelay
        };
      } else {
        console.log("Session Expired")
      }

    }
  },
  getters: {

  },
  actions: {
    showErrorDialog(context, params) {
      return params.modal.msgBoxConfirm(params.text, {
        title: params.title,
        size: 'lg',
        buttonSize: params.buttonSize ? params.buttonSize : 'sm',
        okVariant: 'danger',
        okTitle: params.ok ? params.ok : 'Report Error',
        cancelVariant: 'danger',
        cancelTitle: 'Cancel',
        bodyClass: 'confirm-modal-body',
        hideHeaderClose: true,
        centered: true
      }).then(a => {
        if (a === true) {
          params.callback()
        }
      });
    },
    showConfirmDialog(context, params) {
      return params.modal.msgBoxConfirm(params.text, {
        title: params.title,
        size: 'lg',
        dialogClass:'confirm-dialog',
        buttonSize: params.buttonSize ? params.buttonSize : 'sm',
        okVariant: 'danger',
        okTitle: params.ok ? params.ok : 'Yes',
        cancelVariant: 'primary',
        cancelTitle: params.cancel ? params.cancel : 'Cancel',
        footerClass: 'p-2',
        bodyClass: 'confirm-modal-body',
        modalClass: 'confirm-modal',
        hideHeaderClose: true,
        centered: true
      }).then(a => {
        if (a === true) {
          params.callback()
        }
      })
    }
  }
});

import axios from "axios";

export default {
  state: {
    data: {},
    selectedPipeline: {}
  },
  mutations: {
    SET_SIMILARITY_DATA(state, data) {
        state.data = data;
    }
  },
  actions: {
    getSimilarityData(context, analyzeId) {
      return axios.get(process.env.VUE_APP_BASE_URL + "similarity?analyzeId=" + analyzeId)
        .then(response => {
            if (response && response.status === 200) {
                let containers = new Set(response.data.scores.map(a => a.container));
                let promises = Array.from(containers).map(container => {
                    return context.dispatch('getSharedAccessSignatureWithoutSettingToStore', container)
                });
                Promise.all(promises).then(responseArr => {
                    response.data.scores = response.data.scores.map(similarityEntry => {
                        const sasObject = responseArr.find(sasEntry => sasEntry.container === similarityEntry.container);
                        const sas = sasObject ? sasObject.key : "";
                        similarityEntry.processedSrc = `https://${context.rootState.azureBlobData.data.blobDetails.storageName}.blob.core.windows.net/${similarityEntry.container}/${similarityEntry.thumbnailImgUrl}?${sas}`;
                        return similarityEntry;
                    });
                    context.commit("SET_SIMILARITY_DATA", response.data)
                });
                return true;
            }
            return false
        }).catch(err => {
          let type = 1;
          if(err && err.response && err.response.data && err.response.data.message && err.response.data.message.indexOf("No similarity found for analysis") !== -1) {
              console.log(err.response.data.message)
          } else {
              context.commit("SHOW_MESSAGE", {
                  type: type,
                  message: err && err.response && err.response.data ? err.response.data.message : null,
                  error: err
              });
          }
          context.commit("SET_SIMILARITY_DATA", {});
          return false;
      })
    },
    getAndPlayAnalyzedVideo(context, pipelineId) {
      axios.get(process.env.VUE_APP_BASE_URL + 'pipeline-configs/' + pipelineId)
        .then(response => {
          if (response && response.status === 200) {

              context.commit('SET_FULL_VIDEO_DURATION', response.data.scene.metadata.duration);
              context.commit('PAUSE_VIDEO', 0);
              context.dispatch('changeVideoDetails', {
                    src: response.data.scene.analyzedFilePath ? response.data.scene.analyzedFilePath : response.data.scene.filePath,
                    container: response.data.scene.containerName,
                    originalSrc: response.data.scene.filePath,
                    analysisSrc: response.data.scene.analyzedFilePath ? response.data.scene.analyzedFilePath : '',
                    fileName: response.data.scene.filePath.substring(response.data.scene.filePath.lastIndexOf("/") + 1, response.data.scene.filePath.length),
                    pipelineName: response.data.name,
                    id: response.data.id,
                    analyzeId: response.data.scene.analyzeId,
                    thumbnailUrl: response.data.scene.thumbnailImgUrl,
                    playlistCompletedPercentage: response.data.scene.analysisState,
                    frameRate: response.data.scene.metadata.frameRate,
                    pipeline: response.data
                });
              if(!context.rootState.manageAoi.data.analyzeRequestsSubscriptions[response.data.scene.analyzeId]){
                  context.dispatch("subscribeToWebsocketTopicOfAnalysedVideo", response.data.scene.analyzeId)
              }
              context.commit('SHOW_ALL_PANELS', false);
              context.dispatch('reloadAllVideoAnalytics', response.data.scene.analyzeId);
          }
        }).catch(err => {
            context.commit("SHOW_MESSAGE", {
              type: 1,
              message: err && err.response && err.response.data ? err.response.data.message : null,
              error: err
            });
      });

    }
  }
}

<template>
    <div class="flex column text-left pipeline-info-wrapper" v-if="pipeline">
        <div class="flex column pt-2 pl-1 pb-2" v-if="showTitleDetails">
            <span class="prop-title">Workspace</span>
            <span class="ellipsis prop-text">Name: <span class="prop-value">{{pipeline.scene.name}}</span></span>
            <span class="ellipsis prop-text">Container: <span class="prop-value">{{pipeline.scene.containerName}}</span></span>
            <span class="ellipsis prop-text">FilePath: <span class="prop-value">{{pipeline.scene.filePath}}</span></span>
        </div>

        <div class="divider" v-if="showTitleDetails"></div>
        <div class="prop-wrapper">
            <div class="prop-column">
                <span class="prop-title">Peak</span>
                <span class="ellipsis prop-text">Delay: <span class="prop-value">{{pipeline.behaviors[0].spike.delay}}</span></span>
                <span class="ellipsis prop-text">Rate of Change: <span class="prop-value">{{pipeline.behaviors[0].spike.rateOfChange}}</span></span>
            </div>
            <div class="divider-vertical"></div>
            <div class="prop-column">
                <span class="prop-title">Valley</span>
                <span class="ellipsis prop-text">Delay: <span class="prop-value">{{pipeline.behaviors[0].treewell.delay}}</span></span>
                <span class="ellipsis prop-text">Rate of Change: <span class="prop-value">{{pipeline.behaviors[0].treewell.rateOfChange}}</span></span>
            </div>
        </div>
        <div class="divider"></div>
        <div class="prop-wrapper">
            <div class="prop-column">
                <span class="prop-title">Surge</span>
                <span class="ellipsis prop-text">Rate of Change: <span class="prop-value">{{pipeline.behaviors[0].surge.rateOfChange}}</span></span>
            </div>
            <div class="divider-vertical"></div>
            <div class="prop-column">
                <span class="prop-title">Dive</span>
                <span class="ellipsis prop-text">Rate of Change: <span class="prop-value">{{pipeline.behaviors[0].dive.rateOfChange}}</span></span>
            </div>
        </div>
        <div class="divider"></div>
        <div class="prop-wrapper">
            <div class="prop-column">
                <span class="prop-title">Oscillation</span>
                <span class="ellipsis prop-text">Delay: <span class="prop-value">{{pipeline.behaviors[0].oscillation}}</span></span>
            </div>
            <div class="divider-vertical"></div>
            <div class="prop-column">
                <span class="prop-title">Anomaly</span>
                <span class="ellipsis prop-text">Anomaly: <span class="prop-value">{{pipeline.behaviors[0].abnormality}}</span></span>
            </div>
        </div>
        <div class="divider"></div>
        <div class="prop-wrapper">
            <div class="prop-column">
                <span class="prop-title">Processing</span>
                <span class="ellipsis prop-text">Smoothing: <span class="prop-value">{{pipeline.scene.smoothWindowLength}}</span></span>
                <span class="ellipsis prop-text">BatchSize: <span class="prop-value">{{pipeline.scene.batchSize}}</span></span>
            </div>
            <div class="divider-vertical"></div>
            <div class="prop-column">
            </div>
        </div>




    </div>
</template>

<script>
    export default {
        name: "PipelineInfoTooltip.vue",
        props: {
            showTitleDetails: Boolean,
            pipeline: Object
        }
    }
</script>

<style lang="scss">
 .pipeline-info-wrapper{
     padding-left: 10px;
     padding-right: 10px;
     .divider {
         border-bottom: 1px solid #353C45;
     }
     .prop-text{
        font-size: 14px;
        color: #9098A3;
     }
     .prop-title {
         font-family: "Roboto", Roboto-Bold, sans-serif;
         font-size: 16px;
         color: #CED6E0;
     }
     .prop-value{
         color: #CED6E0;
     }

     .prop-wrapper{
         display: flex;
         flex-direction: row;
         flex-wrap: nowrap;
         justify-content: space-between;
         width: 100%;
         .divider-vertical {
             border-left: 1px solid #353C45;
         }
     .prop-column{

             padding: 5px 5px 5px 10px;
             width: 50%;
             display: flex;
             flex-direction: column;
         }
     }
 }
</style>

import axios from 'axios';
import Vue from 'vue';

export default {
    state: {
        values: [],
        showTimeline: false,
        showLinechart: false,
        timelineBrushSelection: null,
        timelineSelectedBehavior: null,
    },
    mutations: {
        SET_LINECHART_DATA(state, data) {
            state.values = data.values;
        },
        SET_TIMELINE_INTERVAL_SELECTION(state, selection) {
            state.timelineBrushSelection = selection
        },
        SET_TIMELINE_BEHAVIOR_SELECTION(state, behavior) {
            state.timelineSelectedBehavior = behavior
        },
    },
    actions: {

    }
}

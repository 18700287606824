import Vue from 'vue';
import VueRouter from 'vue-router';
import VueCookies from 'vue-cookies'
import users from "./store/modules/users";
import * as axios from "axios";
import store from './store';

Vue.use(VueRouter);
Vue.use(VueCookies);

const router = new VueRouter({
  mode: 'history',
  routes: [{
    path: '/',
    name: 'home',
    component: () => import('./views/home/Home.vue'),
    meta: {
      title: 'NASH'
    },
    /*
    children: [
      {
        path: '/',
        name: 'dashboard',
        component: () => import('./views/home/children/Dashboard.vue')
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('./views/home/children/About.vue')
      }
    ],
    */
    beforeEnter: (to, from, next) => {
        checkIfUserIsLogged(next);
    }
  },
  {
      path: '/settings',
      name: 'settings',
      component: () => import('./views/settings/Settings.vue'),
      meta: {
        title: 'NASH - Settings'
      },
      beforeEnter: (to, from, next) => {
        store.dispatch("getMode").then(resp => {
          if(resp){
            next("/")
          } else {
            checkIfUserIsLogged(next, false, false);
          }
        });
      }
    },
    {
      path: '/users',
      name: 'users',
      component: () => import('./views/users/Users.vue'),
      meta: {
        title: 'NASH - Users'
      },
      beforeEnter: (to, from, next) => {
        store.dispatch("getMode").then(resp => {
          if(resp){
            next("/")
          } else {
            checkIfUserIsLogged(next, false, false);
          }
        });
      }
    },
  {
    path: '/manageaoi',
    name: 'manageaoi',
    component: () => import('./views/home/ManageAoI.vue'),
    meta: {
      title: 'NASH - Manage AOI'
    },
    children: [
      {
        path: '',
        name: 'startPanel',
        component: () => import('./components/manageAoi/StartPanel.vue')
      },
      {
        path: 'pipelines',
        name: 'pipelines',
        component: () => import('./components/Workspaces.vue')
      },
      {
        path: 'preview',
        name: 'preview',
        component: () => import('./components/Workspaces.vue')
      },
      // {
      //   path: 'pipeline',
      //   name: 'workflows',
      //   children: [
      //     {
      //       path: '',
      //       name: 'scene',
      //       component: () => import('./components/manageAoi/SceneConfiguration.vue'),
      //       props: { isEdit: false}
      //     },
      //     {
      //       path: 'behaviors',
      //       name: 'behaviors',
      //       component: () => import('./components/manageAoi/BehaviorConfiguration.vue'),
      //       props: { isEdit: true }
      //     },
      //   ],
      //   component: () => import('./components/manageAoi/Pipeline.vue'),
      //   beforeEnter:(to, from, next) => {
      //     beforeEnterOnPipeline(to, from, next)
      //   }
      // },
      {
        path: 'pipeline/:id',
        name: 'pipeline',
        children: [
          {
            path: '',
            name: 'scene',
            component: () => import('./components/manageAoi/SceneConfiguration.vue'),
          },
          {
            path: 'settings',
            name: 'behaviors',
            component: () => import('./components/manageAoi/BehaviorConfiguration.vue'),
          },
          {
            path: 'confirm',
            name: 'completion',
            component: () => import('./components/manageAoi/FinalPanel.vue')
          },
        ],
        component: () => import('./components/manageAoi/Pipeline.vue'),
        beforeEnter: (to, from, next) => {
          beforeEnterOnPipeline(to, from, next)
        }
      },
    ],
    beforeEnter: (to, from, next) => {
      checkIfUserIsLogged(next);
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('./views/Login.vue'),
    meta: {
      title: 'NASH - Login'
    },
    beforeEnter: (to, from, next) => {
      const currentRoute = router.history;
      if(to.query.unauthorized) {
        if(currentRoute.current.path !== '/login'){
          next();
        }
      } else {
        checkIfUserIsLogged(next, true);
      }
    }
  },
  {
    path: '/self_approve',
    name: 'selfApprove',
    component: () => import('./views/SelfApprove.vue'),
    meta: {
      title: 'NASH - Approve'
    },
    beforeEnter: (to, from, next) => {
      next();
      let {id,token} = to.query;
      if(id && token){
        next();
      } else {
        next("/login")
      }
    }
  },
  {
    path: '/changePassword',
    name: 'changePassword',
    component: () => import('./views/ChangePassword.vue'),
    meta: {
      title: 'NASH - Change'
    },
    beforeEnter: (to, from, next) => {
      let {id,token} = to.query;
      if(id && token) {
        validatePasswordResetToken(id,token)
        .then(response => {
          next();
        }).catch(err => {
          store.commit("SHOW_MESSAGE", {
            type: 1,
            message: err && err.response && err.response.data ? err.response.data.message : null,
            error: err
          });
          next("/login")
        })
      } else {
        next("/login")
      }
    }
  },
  {
    path: '/authenticateazure',
    name: 'oauth',
    beforeEnter: (to, from, next) => {
      next("/")
    }
  },
  { path: "*", component: () => import('./views/NotFound.vue') }

  ]
});

function validatePasswordResetToken(id,token) {
  return axios.get(`${process.env.VUE_APP_BASE_URL}validate_password_reset_token?id=${id}&token=${token}`, {},{withCredentials: true});
}

function mapUserDetails (data) {
  if(data == null || !data.email){
    console.log('Invalid user details!');
    return false;
  } else {
    users.mutations.SET_USER_DATA(users.state, data);
    return true;
  }
}

function beforeEnterOnPipeline(to, from, next) {
  let modelObject = {
    scene: {
      name: '',
      filePath: '',
      containerName: ''
    },
    behaviors: {
      spike: {
        delay: 1,
        rateOfChange: 2
      },
      surge: {
        rateOfChange: 2
      },
      dive: {
        rateOfChange: 2
      },
      treewell: {
        delay: 1,
        rateOfChange: 2
      },
      oscillation: 3,
      abnormality: 5,
      smoothWindowLength: 1,
      batchSize: 1,
    }
  };
  if(to.params.id && to.params.id !== 'new'){
    if(isNaN(to.params.id)){
      next("/manageaoi")
    } else {
      store.dispatch('getPipelineConfig', {id: to.params.id, skipLoad: true})
          .then(pipeline => {
        if (pipeline) {
          modelObject.scene = {
            name: pipeline.name,
            filePath: pipeline.scene.filePath,
            containerName: pipeline.scene.containerName
          };
          modelObject.behaviors = pipeline.behaviors[0];
          to.params.modelObject = modelObject;
          to.params.isEdit = true;
          next()
        }
      });

    }
  } else {
    if(to.name === 'completion' || to.name === 'behaviors') {
      next("/manageaoi/pipeline/new")
    } else {
      to.params.modelObject = modelObject;
      to.params.isEdit = false;
      next()
    }

  }
}


function checkIfUserIsLogged(next, isLogin, requireAdmin) {
        if(users.state.currentUser && users.state.currentUser.email){
          if(requireAdmin && users.state.currentUser.roles && users.state.currentUser.roles.indexOf("ROLE_ADMIN") === -1){
            next("/")
          } else {
            next();
          }
        } else {

            axios.post(`${process.env.VUE_APP_BASE_URL}principal`, {},{withCredentials: true})
                .then(a => {
                  const currentRoute = router.history.current;

                  if(mapUserDetails(a.data)){
                    // we are logged, we should check if user has access to this page, otherwise redirect to homepage
                    if(requireAdmin && users.state.currentUser && users.state.currentUser && users.state.currentUser.roles && users.state.currentUser.roles.indexOf("ROLE_ADMIN") === -1){
                      next("/")
                    } else {
                      if(isLogin) {
                        next("/")
                      } else {
                        next()
                      }
                    }
                  } else {
                    // We are not logged, we should go to login, or if we are on login page,
                    // we should just let router enter on that page
                      if(currentRoute.path !== "/login"){
                        next("/login")
                      }
                  }
                })
                .catch(() => {
                  const currentRoute = router.history.current;
                  if(isLogin){
                    if(currentRoute.path !== "/login") {
                      next()
                    }
                  }
                })
        }
}

export default router;

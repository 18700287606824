<template>
  <div>
    <div class="">
      <span v-on:click="open()">{{ text }}</span>
    </div>
    <b-modal
      @close="close()"
      id="default-dialog"
      ref="userModal"
      no-close-on-backdrop
      centered
      hide-footer
      :title="title"
    >
      <form @submit.prevent="save">
        <b-col class="p-0">
          <b-form-group
            :disabled="type === 'edit'"
            class="custom-field-wrapper"
          >
            <label class="custom-label">Email</label>

            <b-form-input
              :class="{ 'validation-error': $v.user.email.$error }"
              :state="validateState('email')"
              v-model="$v.user.email.$model"
              class="custom-field custom-field-gray-bg"
              :disabled="isEdit === true"
            ></b-form-input>
            <b-form-invalid-feedback v-if="!$v.user.email.required"
              >Email must not be empty.</b-form-invalid-feedback
            >
            <b-form-invalid-feedback v-if="!$v.user.email.email"
              >Email must be valid.</b-form-invalid-feedback
            >
          </b-form-group>

          <b-row>
            <b-form-group class="custom-field-wrapper mr-2">
              <label class="custom-label">First Name</label>

              <b-form-input
                :class="{ 'validation-error': $v.user.firstName.$error }"
                :state="validateState('firstName')"
                v-model="$v.user.firstName.$model"
                class="custom-field custom-field-gray-bg"
              ></b-form-input>
              <b-form-invalid-feedback v-if="!$v.user.firstName.required"
                >First Name must not be empty.</b-form-invalid-feedback
              >
            </b-form-group>
            <b-form-group class="custom-field-wrapper ml-2">
              <label class="custom-label">Last Name</label>

              <b-form-input
                :class="{ 'validation-error': $v.user.lastName.$error }"
                :state="validateState('lastName')"
                v-model="$v.user.lastName.$model"
                class="custom-field custom-field-gray-bg"
              ></b-form-input>
              <b-form-invalid-feedback v-if="!$v.user.lastName.required"
                >Last Name must not be empty.</b-form-invalid-feedback
              >
            </b-form-group>
          </b-row>
          <b-row v-if="!isEdit">
            <b-form-group class="custom-field-wrapper mr-2" v-if="!isEdit || (isEdit && $v.user.email.$model === $store.state.users.currentUser.email)">
              <label class="custom-label">Password</label>

              <b-form-input
                :class="{ 'validation-error': $v.user.password.$error }"
                :state="validateState('password')"
                v-model="$v.user.password.$model"
                type="password"
                class="custom-field custom-field-gray-bg"
              ></b-form-input>
              <b-form-invalid-feedback v-if="!$v.user.password.required"
                >Password must not be empty.</b-form-invalid-feedback
              >
              <b-form-invalid-feedback v-if="!$v.user.password.pattern"
                >Password must contain: uppercase letter, lowercase letter,
                number, special character and be at least 8 characters
                long.</b-form-invalid-feedback
              >
            </b-form-group>
            <b-form-group class="custom-field-wrapper ml-2"  v-if="!isEdit || (isEdit && $v.user.email.$model === $store.state.users.currentUser.email)">
              <label class="custom-label">Confirm Password</label>

              <b-form-input
                :class="{ 'validation-error': $v.user.confirmPassword.$error }"
                :state="validateState('confirmPassword')"
                v-model="$v.user.confirmPassword.$model"
                type="password"
                class="custom-field custom-field-gray-bg"
              ></b-form-input>
              <b-form-invalid-feedback v-if="!$v.user.confirmPassword.required"
                >Confirm Password must not be empty.</b-form-invalid-feedback
              >
              <b-form-invalid-feedback v-if="!$v.user.confirmPassword.isSame"
                >Passwords do not match.</b-form-invalid-feedback
              >
            </b-form-group>
          </b-row>
        </b-col>
        <b-col class="p-0 footer mt-2">
          <b-button
            class="mr-3"
            variant="outline-secondary"
            type="button"
            v-on:click="close"
          >
            Cancel
          </b-button>
          <b-button class="" variant="primary" type="submit">
            {{isEdit ? 'Save' : 'Submit'}}
          </b-button>
        </b-col>
      </form>
    </b-modal>
  </div>
</template>

<script>
import {
  required,
  sameAs,
  numeric,
  minValue,
  maxValue,
  email,
  requiredIf
} from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";

export default {
  name: "UserModal",
  mixins: [validationMixin],
  components: {},
  props: {
    userObj: {
      type: Object,
      default() {
        return {};
      }
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: "User"
    },
    text: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "registerSelf"
    }
  },
  validations: {
    user: {
      email: {
        required,
        email
      },
      firstName: {
        required
      },
      lastName: {
        required
      },
      confirmPassword: {
        required: requiredIf(function(value) {
          return this.type !== "edit";
        }),
        isSame(value) {
          return this.$v.user.password.$model === value;
        }
      },
      password: {
        required: requiredIf(function(value) {
          return this.type !== "edit";
        }),
        pattern(value) {
          if (value) return this.$store.state.users.passwordRegex.test(value);
          return true;
        }
      }
    }
  },
  data() {
    return {
      user: {
        email: "",
        firstName: "",
        lastName: "",
        password: "",
        confirmPassword: "",
        enabled: true
      }
    };
  },
  beforeDestroy(){
    this.close();
  },

  computed: {},
  methods: {
    init() {
      switch (this.type) {
        case "edit": {
          this.user = {
            email: this.userObj.email,
            firstName: this.userObj.firstName,
            lastName: this.userObj.lastName,
            password: "",
            confirmPassword: "",
            enabled: true
          };
          break;
        }
        case "register": {
          this.resetForm();
          break;
        }
      }
    },
    touchInvalidControls() {
      Object.keys(this.$v.user)
        .filter(key => key.indexOf("$") === -1)
        .forEach(key => {
          const control = this.$v.user[key];
          if (control.$invalid) {
            control.$touch();
          }
        });
    },
    isFormValid() {
      const isValid = !this.$v.user.$invalid;
      if (!isValid) this.touchInvalidControls();
      return isValid;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.user[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.user = {
        email: "",
        firstName: "",
        lastName: "",
        password: "",
        confirmPassword: "",
        enabled: true
      };
      this.$v.$reset();
    },
    editingCurrentUser(email) {
      return this.$store.state.users.currentUser.email === email;
    },
    close() {
      this.$refs.userModal.hide();
      this.resetForm();
    },
    open() {
      this.$refs.userModal.show();
      this.init();
    },
    async save() {
      if (this.isFormValid()) {
        let response;
        switch (this.type) {
          case "register": {
            this.user.roles = "ROLE_USER";
            response = await this.$store.dispatch("handleRegister", this.user);
            break;
          }
          case "edit": {
            this.user.roles = this.userObj.roles;
            response = await this.$store.dispatch("handleEditUser", this.user);
            if (this.editingCurrentUser(this.user.email))
              this.$store.dispatch("fetchCurrentUser");
            break;
          }
          case "registerSelf": {
            response = await this.$store.dispatch(
              "handleRegisterSelf",
              this.user
            );
            break;
          }
        }
        if (response) {
          this.$v.$reset();
          this.resetForm();
          this.close();
        }
      }
    }
  }
};
</script>
<style lang="scss">
#default-dialog {
  .modal-dialog {
    min-width: 750px;
    .modal-content {
      padding: 0px 12px;
      border-radius: 8px;
      header {
        display: flex;
        align-items: center;
        padding: 10px 15px !important;
        border: none;
        h5 {
          text-align: left;
        }
        button {
          color: white;
          opacity: 9;
        }
      }
      .custom-field-wrapper {
        width: 100%;
        .custom-label {
          color: white;
          font-size: 14px;
          margin-bottom: 0;
        }
        .custom-field {
          padding: 4px 4px 4px 5px;
          font-size: 14px;
          font-weight: 400;
          color: white;
          background-color: #fff;
          border: 0;
          margin-top: 8px;
        }
        input:disabled {
          border: none;
        }
        .custom-field-gray-bg {
          background-color: rgb(33, 44, 56);
          border: 1px solid #4b5561;
        }
        .custom-field-gray-bg:focus {
          box-shadow: 0 0 0 0.2rem rgba(13, 150, 255, 1);
          border: none;
        }
      }
      .row {
        fieldset {
          flex: 1;
        }
      }
      .custom-checkbox {
        color: white;
      }
      .footer {
        display: flex;
        justify-content: flex-end;
        button {
          font-size: 14px;
          min-width: 100px;
        }
      }
    }
  }
}
</style>

<template>
    <b-modal
            id="cpm-dialog"
            ref="changePasswordModal"
            centered
            header-class="change-password-header"
            hide-footer
            @close="close"
            no-close-on-backdrop
            title="Change Password">
            <form @submit.prevent="save">
                <b-col class="p-0">
                    <b-form-group class="custom-field-wrapper">
                        <label class="custom-label">Old Password</label>

                        <b-form-input
                                debounce="500"
                                :class="{ 'validation-error': $v.form.oldPassword.$error }"
                                :state="validateState('oldPassword')"
                                v-model="$v.form.oldPassword.$model"
                                type="password"
                                class="custom-field custom-field-gray-bg"
                        ></b-form-input>
                        <b-form-invalid-feedback v-if="!$v.form.oldPassword.required">Old Password must not be empty.</b-form-invalid-feedback>
                        <b-form-invalid-feedback v-if="!$v.form.oldPassword.pattern">
                            Password must contain: uppercase letter, lowercase letter,
                            number, special character and be at least 8 characters
                            long.
                        </b-form-invalid-feedback>
                        <b-form-invalid-feedback v-if="!$v.form.oldPassword.isOldPasswordConfirmed">Wrong password.</b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group class="custom-field-wrapper">
                        <label class="custom-label">New Password</label>

                        <b-form-input
                                :class="{ 'validation-error': $v.form.password.$error }"
                                :state="validateState('password')"
                                v-model="$v.form.password.$model"
                                type="password"
                                class="custom-field custom-field-gray-bg"
                        ></b-form-input>
                        <b-form-invalid-feedback v-if="!$v.form.password.required"
                        >New Password must not be empty.</b-form-invalid-feedback
                        >
                        <b-form-invalid-feedback v-if="!$v.form.password.pattern"
                        >Password must contain: uppercase letter, lowercase letter,
                            number, special character and be at least 8 characters
                            long.</b-form-invalid-feedback
                        >
                    </b-form-group>
                    <b-form-group class="custom-field-wrapper">
                        <label class="custom-label">Confirm Password</label>

                        <b-form-input
                                :class="{ 'validation-error': $v.form.confirmPassword.$error }"
                                :state="validateState('confirmPassword')"
                                v-model="$v.form.confirmPassword.$model"
                                type="password"
                                class="custom-field custom-field-gray-bg"
                        ></b-form-input>
                        <b-form-invalid-feedback v-if="!$v.form.confirmPassword.required"
                        >Confirm Password must not be empty.</b-form-invalid-feedback
                        >
                        <b-form-invalid-feedback v-if="!$v.form.confirmPassword.isSame"
                        >Passwords do not match.</b-form-invalid-feedback
                        >
                    </b-form-group>
                </b-col>
                <b-col class="p-0 change-password-footer footer mt-2">
                    <b-button
                            class="mr-3"
                            variant="outline-secondary"
                            type="button"
                            v-on:click="close"
                    >
                        Cancel
                    </b-button>
                    <b-button class="" variant="primary" type="submit">
                        Change Password
                    </b-button>
                </b-col>
            </form>
   </b-modal>
</template>

<script>
    import { required } from "vuelidate/lib/validators";
    import { validationMixin } from "vuelidate";
    export default {
        name: "ChangePasswordModal",
        mixins: [validationMixin],
        validations: {
            form: {
                oldPassword: {
                    required,
                    pattern(value) {
                        if (value) return this.$store.state.users.passwordRegex.test(value);
                        return true;
                    },
                    async isOldPasswordConfirmed(value) {
                        // standalone validator ideally should not assume a field is required
                        if (!value) return true;
                        if(!this.$store.state.users.passwordRegex.test(value)) return true;

                        // simulate async call, fail for all logins with even length
                        return await this.$store.dispatch('checkIfOldPasswordMatch', {
                            oldPassword: btoa(value),
                        });
                    }
                },
                password: {
                    required,
                    pattern(value) {
                        if (value) return this.$store.state.users.passwordRegex.test(value);
                        return true;
                    }
                },
                confirmPassword: {
                    required,
                    isSame(value) {
                        return this.$v.form.password.$model === value;
                    }
                }
            }
        },
        data() {
            return {
                form: {
                    oldPassword: "",
                    password: "",
                    confirmPassword: ""
                }
            };
        },
        methods: {
            validateState(name, validate = false) {
                const { $dirty, $error } = this.$v.form[name];
                return $dirty ? !$error : null;
            },
            touchInvalidControls() {
                Object.keys(this.$v.form)
                    .filter(key => key.indexOf("$") === -1)
                    .forEach(key => {
                        const control = this.$v.form[key];
                        if (control.$invalid) {
                            control.$touch();
                        }
                    });
            },
            isFormValid() {
                const isValid = !this.$v.form.$invalid;
                if (!isValid) this.touchInvalidControls();
                return isValid;
            },
            open() {
                this.$refs.changePasswordModal.show();
            },
            close() {
                this.form = {
                        oldPassword: "",
                        password: "",
                        confirmPassword: ""
                };
                this.$v.form.$reset();
                this.$refs.changePasswordModal.hide();

            },
            async save() {
                if (this.isFormValid()) {
                    let response = await this.$store.dispatch("handleUpdateUserPassword", {
                        oldPassword: btoa(this.form.oldPassword),
                        password: btoa(this.form.password)
                    });
                    if(response) {
                        this.close()
                    }


                }
            }
        }
    }
</script>

<style lang="scss">
    .change-password-footer{
        display: flex;
        flex-direction: row;
        margin-left: auto;
        justify-content: flex-end;
    }
    .change-password-header{
        button {
            line-height: 0.6;
            align-self: center;
            padding: 1px 5px 5px 5px!important;
            margin: 0!important;
            font-size: 1.5em!important;
            color: #CED6E0 !important;
            border-radius: 5px;
        }
        button:focus {
            outline: none;
        }
        button:hover {
            background-color: #fff !important;
            color: rgb(33, 44, 56) !important;
            font-size: 16px;
        }
    }


</style>

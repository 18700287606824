var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"segment-grid-wrapper"},[(_vm.rows && _vm.segmentsData)?_c('div',{staticClass:"grid-container",style:({'grid-template-rows': 'repeat(' + _vm.rows + ', 16px)'}),on:{"scroll":function($event){return _vm.onContainerScroll($event)}}},_vm._l((_vm.segmentsData),function(segment){return _c('div',{key:segment.index,staticClass:"grid-item",class:{
                'in-progress': segment.status === 'not_started',
                'success': segment.status === 'success',
                'danger': segment.status === 'danger' || segment.status === 'stopped',
                // 'primary': segment.status === 'primary',
                'blinking': segment.status === 'primary',
                'last-right': _vm.segmentsData.length % 8 !== 0 && segment.index === (_vm.segmentsData.length - 1 - ( _vm.segmentsData.length % 8 )),
                'last-left': segment.index === _vm.segmentsData.length - ( _vm.segmentsData.length % 8 + (_vm.segmentsData.length % 8 === 0 ? 8 : 0))
             },style:({cursor: segment && segment.data ? 'pointer' : 'unset'}),attrs:{"id":'grid-processing-tooltip-target-' + segment.index}},[(segment.firstSegmentOfBatch && segment.batchId !== null && segment.batchId !== undefined)?_c('div',{staticClass:"batch-separator",class:{'first-batch-separator': segment.batchId === 0 }},[_vm._v(_vm._s(segment.batchId + 1))]):_vm._e(),_c('div',{staticClass:"pseudo-elm",class:{
                    'last-right': _vm.segmentsData.length % 8 !== 0 && segment.index === (_vm.segmentsData.length - 1 - ( _vm.segmentsData.length % 8 )),
                    'last-left':  segment.index === _vm.segmentsData.length - ( _vm.segmentsData.length % 8 + (_vm.segmentsData.length % 8 === 0 ? 8 : 0))
                },style:({marginLeft: segment.firstSegmentOfBatch && segment.batchId !== null && segment.batchId !== undefined ? '-10px' : ''})},[_c('span',[_vm._v(_vm._s(segment.index + 1))])])])}),0):_vm._e(),_vm._l((_vm.segmentsData),function(segment){return _c('b-tooltip',{key:segment.index,attrs:{"boundary":"viewport","placement":"bottom","custom-class":"progress-tooltip","target":'grid-processing-tooltip-target-' + segment.index,"triggers":"click"}},[(segment && segment.data)?_c('segments-tooltip',{attrs:{"tooltipId":'grid-processing-tooltip-target-' + segment.index,"type":1,"segment":segment}}):_vm._e()],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div class="segment-grid-wrapper">
        <div v-on:scroll="onContainerScroll($event)" v-if="rows && segmentsData" :style="{'grid-template-rows': 'repeat(' + rows + ', 16px)'}" class="grid-container">
            <div class="grid-item"
                 v-for="segment of segmentsData" :key="segment.index"
                 :id="'grid-processing-tooltip-target-' + segment.index"
                 :class="{
                    'in-progress': segment.status === 'not_started',
                    'success': segment.status === 'success',
                    'danger': segment.status === 'danger' || segment.status === 'stopped',
                    // 'primary': segment.status === 'primary',
                    'blinking': segment.status === 'primary',
                    'last-right': segmentsData.length % 8 !== 0 && segment.index === (segmentsData.length - 1 - ( segmentsData.length % 8 )),
                    'last-left': segment.index === segmentsData.length - ( segmentsData.length % 8 + (segmentsData.length % 8 === 0 ? 8 : 0))
                 }"
                 :style="{cursor: segment && segment.data ? 'pointer' : 'unset'}"
            >
                <div class="batch-separator" :class="{'first-batch-separator': segment.batchId === 0 }" v-if="segment.firstSegmentOfBatch && segment.batchId !== null && segment.batchId !== undefined">{{segment.batchId + 1}}</div>
                <div  class="pseudo-elm"
                    :style="{marginLeft: segment.firstSegmentOfBatch && segment.batchId !== null && segment.batchId !== undefined ? '-10px' : ''}"
                    :class="{
                        'last-right': segmentsData.length % 8 !== 0 && segment.index === (segmentsData.length - 1 - ( segmentsData.length % 8 )),
                        'last-left':  segment.index === segmentsData.length - ( segmentsData.length % 8 + (segmentsData.length % 8 === 0 ? 8 : 0))
                    }"
                ><span>{{segment.index + 1}}</span></div>
            </div>
        </div>

        <b-tooltip boundary="viewport" placement="bottom" custom-class="progress-tooltip" :target="'grid-processing-tooltip-target-' + segment.index" v-for="segment of segmentsData" :key="segment.index" triggers="click">
            <segments-tooltip :tooltipId="'grid-processing-tooltip-target-' + segment.index" v-if="segment && segment.data" :type="1" :segment="segment"></segments-tooltip>
        </b-tooltip>
    </div>


</template>

<script>
    import SegmentsTooltip from '@/components/videoProcessingVisualizationComponents/SegmentsTooltip.vue'

    export default {
        name: "VideoProcessingGrid",
        components: {
            SegmentsTooltip
        },
        props: {
          segmentsData: Array
        },
        data() {
            return {
                rows: 0,
                itemsOnRow: 8,
            }
        },
        methods: {
            onContainerScroll(event) {
                const itemHeight = 16;
                const lowerItems = Math.floor(event.target.scrollTop / itemHeight) * this.itemsOnRow;
                const upperItems = Math.floor((event.target.scrollTop + 110) / itemHeight) * this.itemsOnRow;

                const container = document.getElementsByClassName('grid-container');
                if(container && container.item(0)) {
                    const gridItems = Array.from(container.item(0).children);
                    gridItems.forEach((item, index) => {
                        if(index < lowerItems || index > upperItems) {
                            this.$root.$emit('bv::hide::tooltip', 'grid-processing-tooltip-target-' + index);
                        }
                    })
                }
            }
        },
        beforeMount() {
            this.rows = Math.ceil(this.segmentsData.length / this.itemsOnRow);
        }
    }
</script>

<style lang="scss">
    .grid-container {
        height: 110px;
        overflow: hidden;
        overflow-y: auto;
        display: grid;
        padding: 0;
        grid-template-columns: repeat(8, 12.5%);

        .grid-item {
            background-color: rgba(255, 255, 255, 1);
            border: solid 1px #4B5561;
            color: white;
            display: flex;
            &:first-child {
                .pseudo-elm {
                    border-top-left-radius: 5px;
                }
                border-top-left-radius: 8px;
            }
            &:nth-child(8) {
                .pseudo-elm {
                    border-top-right-radius: 5px;
                }
                border-top-right-radius: 8px;
            }
            &:last-child {
                .pseudo-elm {
                    border-bottom-right-radius: 5px;
                }
                border-bottom-right-radius: 8px;
            }
            &.last-left {
                .pseudo-elm {
                    border-bottom-left-radius: 5px;
                }
                border-bottom-left-radius: 8px;
            }
            &.last-right {
                .pseudo-elm {
                    border-bottom-right-radius: 5px;
                }
                border-bottom-right-radius: 8px;
            }
            .first-batch-separator{
                border-top-left-radius: 5px;
            }
            .batch-separator{
                margin-top: 0;
                width: 14px;
                font-size: 8px;
                line-height: 10px;
                text-align: center;
                background-color: grey;
                height: 10px;
                position: relative;
            }
            .pseudo-elm {
                &.last-left {
                    border-bottom-left-radius: 5px;
                }
                &.last-right {
                    border-bottom-right-radius: 5px;
                }
                background-color: rgba(0, 0, 0, 0);
                height: calc(100% - 4px);
                border-radius: 0;
                font-size: 7px;
                margin: 2px;
                text-align: center;
                width: 100%;
                >span {
                    font-size: 10px;
                    line-height: 10px;
                }
            }
        }

        .grid-item:hover{
            .pseudo-elm{
                background-color: rgba(0, 0, 0, .2);
            }
        }
        .in-progress{
            background-color: rgba(57, 60, 67, 1)!important;
            color: #CED6E0!important;

        }
        .success{
            background-color: #28a745;
            color: white;
        }
        .danger{
            background-color: #FFDF3C;
            color: black;
        }
        .primary{
            background-color: #0D96FF;
            color: white;
        }

        .active-segment{
            .pseudo-elm{
                background-color: rgba(0, 0, 0, .3);
            }
            animation: none!important;
        }
    }


</style>

import router from "../../router";

export default {
    state: {
        duration: 0,
        isPlaying: false,
        currentTime: 0,
        totalVideoDuration: 0,
        newVideoCurrentTime: 0,
        externalIsPlaying: null,
        video: {
            src: '',
            originalSrc: '',
            analysisSrc: '',
            fileName: '',
            pipelineName: '',
            container: '',
            analyzeId: '',
            id: '',
            playlistCompletedPercentage: 'NotStarted',
            isOriginalPlayed: false
        },
        partialPlaylistEvent: false,
    },
    mutations: {
        PLAY_VIDEO(state, moment) {
            state.currentTime = moment;
            state.isPlaying = true;
        },
        PAUSE_VIDEO(state, moment) {
            state.currentTime = moment;
            state.isPlaying = false;
        },
        PAUSE_VIDEO_EXTERNAL(state, isPlaying) {
            state.externalIsPlaying = isPlaying;
        },

        /**
         * @param state
         * @param duration
         * Set duration used by linecharts(can be just a part of video)
         */
        SET_VIDEO_DURATION(state, duration) {
            state.duration = duration;
        },

        /**
         * @param state
         * @param duration
         * Set duration of full video based on pipeline metadata
         */
        SET_FULL_VIDEO_DURATION(state, duration) {
            state.totalVideoDuration = duration;
        },
        /**
         * Mutation that should announce the videoPlayer to change currentTime
         * */
        CHANGE_CURRENT_VIDEO_TIME(state, moment) {
            state.newVideoCurrentTime = moment;
        },
        /**
         * Mutation that should announce charts about duration of video (or change of video duration)
         * */
        TRIGGER_CURRENT_VIDEO_TIME(state, moment) {
            state.currentTime = moment;
        },
        TOGGLE_VIDEO_SRC(state) {
            const cloneVideo = Object.assign({}, state.video);
            if (state.video.isOriginalPlayed) {
                if (state.video.analysisSrc) {
                    cloneVideo.src = state.video.analysisSrc;
                    cloneVideo.isOriginalPlayed = false;
                }
            } else {
                cloneVideo.src = state.video.originalSrc;
                cloneVideo.isOriginalPlayed = true
            }
            state.video = cloneVideo;
        },
        REFRESH_ANALYSIS_SRC(state) {
            const cloneVideo = Object.assign({}, state.video);
            cloneVideo.src = state.video.analysisSrc;
            cloneVideo.isOriginalPlayed = false;
            state.video = cloneVideo;
        },
        CLEAR_VIDEO_SRC(state) {
            const cloneVideo = Object.assign({}, state.video);
            cloneVideo.src = null;
            state.video = cloneVideo;
        },
        CLEAR_VIDEO_OBJECT(state) {
          state.video = {
                  originalSrc: '',
                  analysisSrc: '',
                  fileName: '',
                  pipelineName: '',
                  container: '',
                  id: null,
                  analyzeId: '',
                  playlistCompletedPercentage: 'NotStarted',
                  thumbnailUrl: '',
                  frameRate: 0,
          };
          state.newVideoCurrentTime = 0;
        },
        CHANGE_VIDEO_DETAILS(state, details) {

            state.video =  {
                originalSrc: details.videoDetails.originalSrc,
                analysisSrc: details.videoDetails.analysisSrc ? details.videoDetails.analysisSrc : '',
                fileName: details.videoDetails.originalSrc.substring(details.videoDetails.originalSrc.lastIndexOf("/") + 1, details.videoDetails.originalSrc.length),
                pipelineName: details.videoDetails.pipelineName,
                container: details.videoDetails.container,
                id: details.videoDetails.id,
                analyzeId: details.videoDetails.analyzeId,
                playlistCompletedPercentage: details.videoDetails.playlistCompletedPercentage,
                thumbnailUrl: details.videoDetails.thumbnailUrl,
                frameRate: details.videoDetails.frameRate,
                pipeline: details.videoDetails.pipeline
            };
            state.video.src = details.videoDetails.src ? details.videoDetails.src : (details.videoDetails.analysisSrc ? state.video.analysisSrc : state.video.originalSrc);
            state.video.isOriginalPlayed = state.video.src === state.video.originalSrc;
            state.newVideoCurrentTime = 0;
        },

        TOGGLE_PARTIAL_VIDEO_EVENT(state, shouldDisplayRefresh) {
            state.partialPlaylistEvent = shouldDisplayRefresh;
        },

        CHANGE_PARTIAL_VIDEO_DETAILS(state, details) {
            if(details.analysisSrc){
                state.video.analysisSrc = details.analysisSrc;
            }
            state.partialPlaylistEvent = true;
        },

    },
    actions: {
        changePartialVideoDetails(context, details) {
            context.commit('CHANGE_PARTIAL_VIDEO_DETAILS', details)
        },

        changeVideoDetails(context, details) {
            context.commit('CHANGE_VIDEO_DETAILS', {videoDetails: details});
            if(!details) {
                if(router.currentRoute.query.pipelineId){
                    router.replace({query: {}});
                }
            } else {
                const query = router.currentRoute.query;
                const pending = router.history.pending;
                // query.pieplineId != details.id (extra check for type(!==) not needed, as query param is string and details.id is number
                if(!pending || (pending && pending.path.indexOf('/manageaoi/pipeline') === -1)){
                    if(router.currentRoute.path === '/' && ((query.pipelineId && query.pipelineId != details.id) || (!query.pipelineId))){
                        router.replace({query: {pipelineId: details.id, analyzeId: details.analyzeId}});
                    }
                }

            }
        }

    }
}

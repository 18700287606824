import axios from "axios";

export default {
  state: {
    data:  {}
  },
  mutations: {
    SET_ANOMALY_DATA(state, anomalyData) {
      state.data = anomalyData;
    },
  },
  actions: {
    getAnomalyData(context, analyzeId) {
        return axios.get(process.env.VUE_APP_BASE_URL + 'anomaly?analyzeId=' + analyzeId)
        .then(response => {
            if (response && response.status === 200) {
                context.commit('SET_ANOMALY_DATA', response.data);
                return true;
            }
            return false;

        }).catch(err => {
            let type = 1;
            if(err && err.response && err.response.data && err.response.data.message && err.response.data.message.indexOf("No anomaly found for analysis") !== -1) {
                console.log(err.response.data.message);
            } else {
                context.commit("SHOW_MESSAGE", {
                    type: type,
                    message: err && err.response && err.response.data ? err.response.data.message : null,
                    error: err
                });
            }
              context.commit('SET_ANOMALY_DATA', {});
              return false;

      })
    }
  }
}

import axios from 'axios';
import Vue from 'vue';
export default {
  state: {
    data: {
      analyzeRequestsSubscriptions: {},
    }
  },
  mutations: {

    UPDATE_ANALYTICS_DETAILS(state, data) {
    },

    UPDATE_SIMILARITY_DETAILS(state, data) {
        // data.context.commit('TOGGLE_PARTIAL_VIDEO_EVENT', true);
    },

    UPDATE_PLAYLIST(state, data) {
        data.context.dispatch('changePartialVideoDetails', {
            analysisSrc: data.analyzedVideoFile
        });
    }
  },
  getters: {
  },
  actions: {
      reloadAllVideoAnalytics(context, analyzeId) {
          context.dispatch('getPipelineStatus', context.rootState.videoPlayer.video.id);
          context.dispatch('getAnalyzedVideoBehaviour', analyzeId);
          context.dispatch('getVideoCountMetrics', analyzeId);
          context.dispatch('getSimilarityData', analyzeId);
          context.dispatch('getAnomalyData', analyzeId);
      },
      saveAnalysis(context, d) {

          return axios.post(process.env.VUE_APP_BASE_URL + "analyze-video", d)
              .then((response) => {
                  if(response.data){
                      context.commit("SHOW_MESSAGE", {
                          type: 3,
                          message: "Pipeline saved successfully"
                      });

                      let pathWithAnalyzedVideos = response.data.filePath;
                      if(!context.state.data.analyzeRequestsSubscriptions[pathWithAnalyzedVideos]){
                        context.dispatch("subscribeToWebsocketTopicOfAnalysedVideo", pathWithAnalyzedVideos)
                      }
                      return response.data;
                  }
              })
              .catch((err) => {
                  context.commit("SHOW_MESSAGE", {
                      type: 1,
                      message: err && err.response && err.response.data ? err.response.data.message : null,
                      error: err
                  });
              })

      },
      updateAnalysis(context, d) {

          return axios.put(process.env.VUE_APP_BASE_URL + "pipeline-configs/" + d.id, d)
              .then((response) => {
                  if(!context.state.data.analyzeRequestsSubscriptions[d.analyzeId]){
                      context.dispatch("subscribeToWebsocketTopicOfAnalysedVideo", d.analyzeId)
                  }
                  return response.data;
              })
              .catch((err) => {
                  context.commit("SHOW_MESSAGE", {
                      type: 1,
                      message: err && err.response && err.response.data ? err.response.data.message : null,
                      error: err
                  });
              })
      },

      getPipelineConfig(context, details) {

          return axios.get(process.env.VUE_APP_BASE_URL + "pipeline-configs/" + details.id)
              .then((response) => {
                  let video = response.data;
                  video.behaviors[0].batchSize = +video.scene.batchSize;
                  video.behaviors[0].smoothWindowLength = +video.scene.smoothWindowLength;

                  context.commit('SET_FULL_VIDEO_DURATION', video.scene.metadata.duration);
                  if(!details.skipLoad){
                      context.commit('PAUSE_VIDEO', 0);
                      context.dispatch('changeVideoDetails', {
                          src: video.scene.analyzedFilePath ? video.scene.analyzedFilePath : video.scene.filePath,
                          originalSrc: video.scene.filePath,
                          analyzeId: video.scene.analyzeId,
                          container: video.scene.containerName,
                          id: video.id,
                          pipelineName: video.name,
                          analysisSrc: video.scene.analyzedFilePath,
                          playlistCompletedPercentage: video.scene.analysisState,
                          thumbnailUrl: video.scene.thumbnailImgUrl ? video.scene.thumbnailImgUrl : details.thumbnail,
                          frameRate: video.scene.metadata.frameRate,
                          pipeline: video,
                      });
                      if(!context.state.data.analyzeRequestsSubscriptions[video.scene.analyzeId]){
                          context.dispatch("subscribeToWebsocketTopicOfAnalysedVideo", video.scene.analyzeId)
                      }
                  }
                  return response.data;
              })
              .catch((err) => {
                  context.commit("SHOW_MESSAGE", {
                      type: 1,
                      message: err && err.response && err.response.data ? err.response.data.message : null,
                      error: err
                  });
              })
      },

      clearSubscriptions(context) {
          Object.keys(context.state.data.analyzeRequestsSubscriptions).forEach(key => {
              const subscription = context.state.data.analyzeRequestsSubscriptions[key];
              if(subscription)
                  subscription.unsubscribe();
          });
          context.state.data.analyzeRequestsSubscriptions = {}
      },

      subscribeToErrorEvents(context, payload) {
          if(context.rootState.websocket.isConnected) {
              if (context.state.data.websocketErrorEventsSubscription) {
                  context.state.data.websocketErrorEventsSubscription.unsubscribe();
              }

              context.state.data.websocketErrorEventsSubscription = this.stompClient.subscribe(`${process.env.VUE_APP_BASE_WS_URL}/${payload.email}`, (response) => {
                  const body = JSON.parse(response.body);
                  let messageObject = {
                      analyzeId: body.analyzeId,
                      correlationId: body.data.correlationId,
                      message: body.data.message
                  };
                  const params = {
                      modal: payload.modal,
                      title: "Nash Server Error for " + body.analyzeId,
                      text: messageObject.message,
                      callback: report
                  };

                  function report() {
                      axios.post(`${process.env.VUE_APP_BASE_URL}reported-errors/${messageObject.correlationId}`)
                        .then(response => {
                            context.commit("SHOW_MESSAGE", {
                                type: 3,
                                message: "Error reported successfully"
                            });
                        }).catch((err) => {
                              context.commit("SHOW_MESSAGE", {
                                  type: 1,
                                  message: err && err.response && err.response.data ? err.response.data.message : null,
                                  error: err
                              });
                        })
                  }

                  context.dispatch('showErrorDialog', params);
              }, {id: payload.email});
          }
      },

      subscribeToWebsocketTopicOfAnalysedVideo(context, pathWithAnalyzedVideos) {
          context.dispatch('clearSubscriptions');
          if(context.rootState.websocket.isConnected) {
              context.state.data.analyzeRequestsSubscriptions[pathWithAnalyzedVideos] = this.stompClient.subscribe(`${process.env.VUE_APP_BASE_WS_URL}/${pathWithAnalyzedVideos}`, (response) => {
                  const body = JSON.parse(response.body);

                  switch (body['eventType']) {

                      case "FinalAnalyticsReadyEvent":
                          context.commit('TOGGLE_PARTIAL_VIDEO_EVENT', true);
                          break;

                      case "SimilarityReadyEvent":
                          context.commit('TOGGLE_PARTIAL_VIDEO_EVENT', true);
                          break;

                      case "PartialAnalyticsReadyEvent":
                          context.commit('TOGGLE_PARTIAL_VIDEO_EVENT', true);
                          break;

                      case "AdvancedTrackingVideoPlaylistReadyEvent":
                          if(body.data){
                              context.commit('UPDATE_PLAYLIST', { context, analyzedVideoFile: body.data.analyzedVideoFile});
                          }
                          break;

                      case "PartialPlaylistReadyEvent":
                          if(body.data){
                              context.commit('UPDATE_PLAYLIST', { context, analyzedVideoFile: body.data.analyzedVideoFile});
                          }
                          break;

                      case "PipelineStatusUpdateEvent":
                          context.commit("SET_PIPELINE_STATUS", {context: context, data: body.data});
                  }
              }, { id: pathWithAnalyzedVideos });
          } else {
              context.state.data.analyzeRequestsSubscriptions[pathWithAnalyzedVideos] = null;
          }
      }

  }
}

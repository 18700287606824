<template>
    <div class="progress-tooltip-footer progress-tooltip-row progress-tooltip-footer-rows">
        <span v-if="!title">{{type === 'segment' ? 'Segment' : 'Total'}} duration</span>
        <span v-if="title">{{title}}</span>
        <span class="progress-tooltip-footer-value">{{latency}}</span>
    </div>
</template>

<script>
    import * as moment from 'moment';
    import * as momentDurationFormatSetup from "moment-duration-format";
    export default {
        name: "VideoProcessingVizualisationFooter",
        props: {
            segmentValue: Object,
            type: String,
            title: String,
            processedData: Boolean,
        },
        data() {
            return {
                interval: null,
                latency: ''
            }
        },
        methods: {
            mapLatencyData() {
                clearInterval(this.interval);
                if(!this.processedData){
                    this.mapLatency();
                } else {
                    this.mapProcessedLatency();
                }
            },
            mapLatency() {
                if(!this.segmentValue.endTime) {
                    this.mapLatencyToNow(this.segmentValue.startTime)
                } else {
                    this.mapLatencyBasedOnDurationOrDiff(this.segmentValue.latency, this.segmentValue.startTime, this.segmentValue.endTime)
                }
            },
            mapProcessedLatency() {
                if(!this.segmentValue.processingLatency) {
                    this.mapLatencyToNow(this.segmentValue.processingStartTime)
                } else {
                    this.mapLatencyBasedOnDurationOrDiff(this.segmentValue.processingLatency)
                }
            },
            mapLatencyBasedOnDurationOrDiff(latency, startTime, endTime){
                let duration = 0;
                if(latency){
                    duration = moment.duration(latency, 'milliseconds');
                } else {
                    duration = moment.utc(endTime).diff(moment.utc(startTime), 'milliseconds');
                }
                let format = 'h[h] mm[m] ss[s]';
                if(duration < 1000){
                    format = 'ss[s] SSS[ms]'
                }
                this.latency = moment.duration(duration, 'milliseconds').format(format);
            },
            mapLatencyToNow(startTime) {
                this.interval = setInterval(() => {
                    this.latency =  moment.duration(moment().diff(moment.utc(startTime), 's'),'seconds').format("h[h] mm[m] ss[s]");
                }, 1000);
                this.latency =  moment.duration(moment().diff(moment.utc(startTime), 's'),'seconds').format("h[h] mm[m] ss[s]");
            },
            loadData() {
                this.latency = this.segmentValue.latency;
                this.mapLatencyData()
            }
        },
        watch: {
            segmentValue: function(newValue, oldValue) {
                if(oldValue) {
                    this.loadData();
                }
            }
        },
        beforeMount(){
            momentDurationFormatSetup(moment);
            this.latency = this.segmentValue.latency;
            this.loadData();
        },
        beforeDestroy(){
            clearInterval(this.interval);
        }
    }
</script>

<style scoped>

</style>

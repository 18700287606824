import Vue from 'vue';
import VueCookies from 'vue-cookies'
import SockJS from "sockjs-client";
import Stomp from "stomp-websocket";
Vue.use(VueCookies);
export default {

    state: {
        isConnected: false,
        retryCount: null,
        reconnectDelay: 1000,
        socket: null,
        stompClient: null
    },
    getters: {
        currentConnectionState(state) {
            return state.isConnected;
        },
        getDefaultReconnectDelay() {
            return process.env.VUE_APP_WS_DEFAULT_RECONNECT_DELAY ? +process.env.VUE_APP_WS_DEFAULT_RECONNECT_DELAY : 1000;
        },
        getMaxRetryCount() {
            return process.env.VUE_APP_WS_MAX_CONNECTION_RETRY ? +process.env.VUE_APP_WS_MAX_CONNECTION_RETRY : 30;
        },
    },
    mutations: {
        SOCKET_SET_CONNECTION(state, isConnected) {
            state.isConnected = isConnected;
        },
    },
    actions: {
        connectAndReconnect(context) {
            if(context.state.isConnected)
                return;
            var sockJsProtocols = ["websocket"];

            this.socket = new SockJS(`${process.env.VUE_APP_BASE_WS_URL}`, null, {transports: sockJsProtocols});
            this.stompClient = Stomp.over(this.socket);
            this.stompClient.debug = null;
            if(this.retryCount == null) {
                this.retryCount = 0;
            }
            if(this.reconnectDelay == null) {
                this.reconnectDelay = context.getters.getDefaultReconnectDelay;
            } else {
                this.reconnectDelay = context.getters.getDefaultReconnectDelay * this.retryCount;
            }

            this.stompClient.reconnect_delay = this.reconnectDelay;
            return this.stompClient.connect({}, (frame) => {
                this.retryCount = 0;
                this.reconnectDelay = context.getters.getDefaultReconnectDelay;
                console.log('Connected: ' + frame);
                context.commit("SOCKET_SET_CONNECTION", true);
            }, () => {
                //Fallout if vue-stomp recconect is not working well
                context.commit("SOCKET_SET_CONNECTION", false);
                console.log("Websocket connection lost");
                if(this.retryCount > context.getters.getMaxRetryCount){
                    this.reconnectDelay = context.getters.getDefaultReconnectDelay;
                    this.retryCount = 0;
                    console.log("Websocket connection cannot be established");
                    context.commit("SHOW_MESSAGE", {
                        type: 1,
                        message: "Websocket connection cannot be established"
                    });
                } else {
                    this.retryCount = this.retryCount + 1;
                    let retryMultiplier = 1;
                    switch (true) {
                        case this.retryCount < 5:
                            retryMultiplier = 10;
                            break;
                        case this.retryCount >= 5 && this.retryCount < 10:
                            retryMultiplier = 50;
                            break;
                        case this.retryCount >= 10 && this.retryCount < 15:
                            retryMultiplier = 100;
                            break;
                        case this.retryCount >= 15 && this.retryCount < 20:
                            retryMultiplier = 250;
                            break;
                        case this.retryCount >= 20 && this.retryCount < 25:
                            retryMultiplier = 500;
                            break;
                        case this.retryCount >= 25:
                            retryMultiplier = 1000;
                            break;
                        default: retryMultiplier = 1;
                    }
                    this.reconnectDelay = retryMultiplier * context.getters.getDefaultReconnectDelay * this.retryCount;
                    console.log(`Reconnecting to websocket. Retry number ${this.retryCount}, delayMultiplier: ${retryMultiplier}. Retry will delayed with ${this.reconnectDelay}ms`)
                    setTimeout(() => {
                        if(!context.state.isConnected){
                            console.log("Vue-stomp was unable to reconnect, we'll try to reestablish session");
                            context.dispatch("connectAndReconnect")
                        }
                    }, this.reconnectDelay);
                }


            });
        }
    },
}

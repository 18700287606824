<template>
  <div
    v-on:click="goToHomepage()"
    class="nash-logo"
    :class="{ clickable: clickable }"
  >
    <div>
      <img src="../../assets/images/bplogo.png" class="bp-logo" />
    </div>
    <div class="vert-line"></div>
    <div class="">
      <div class="header-app-name"><span>NASH</span><span v-if="$store.state.settings.isPreviewMode()" class="preview-text">Preview</span></div>
      <div class="header-app-subname">Powered by Blueprint</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Logo",
  props: {
    clickable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  mounted() {},
  destroyed() {},
  methods: {
    goToHomepage() {
      if (this.clickable){
          this.$store.commit("SHOW_ALL_PANELS", false);
          this.$store.commit('CLEAR_VIDEO_OBJECT');
          this.$store.commit('PAUSE_VIDEO', 0);
          this.$store.dispatch('clearSubscriptions');

          if(this.$router.currentRoute.path !== "/") {
            this.$router.push({path: '/', query: {}});
          } else {
            const query = this.$router.currentRoute.query;
            if(query.pipelineId) {
              this.$router.replace({path: '/', query: {}})
            }
          }
      }
    }
  }
};
</script>

<style lang="scss">
.nash-logo {
  align-items: center;
  color: #dedfe1;
  display: flex;
  .bp-logo {
    width: 35px;
    height: 35px;
  }
  .header-app-name {
    font-family: "Roboto", Roboto-Bold, sans-serif;
    font-weight: 500;
    letter-spacing: 2px;
    .preview-text{
      font-size: 12px;
      color: #007bff;
      margin-left: 5px;
    }
  }
  .header-app-subname {
    font-size: 12px;
    margin-top: -2px;
  }
  .vert-line {
    margin-left: 5px;
    border-left: 1px solid #e1ebf5;
    height: 34px;
    width: 10px;
    color: #e1ebf5;
  }
}
</style>

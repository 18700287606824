import axios from "axios";
import router from "../../router";
import store from "@/store";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

export default {
  //namespaced: true,
  state: {
    passwordRegex: /^(?=.{8,})((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])).*/,
    users: [],
    currentUser: {
      isAdmin: false
    },
    approvalUsers: [],
    columns: [
      { key: "email", label: "Email", sortable: true },
      { key: "firstName", label: "First Name", sortable: true },
      { key: "lastName", label: "Last Name", sortable: true },
      { key: "editColumn", label: "Edit" },
      { key: "deleteColumn", label: "Del" }
    ]
  },
  getters: {
    currentUser(state) {
      return state.currentUser;
    }
  },
  mutations: {
    SET_APPROVAL_USERS(state, data) {
      state.approvalUsers = data;
    },
    SET_USERS(state, data) {
      state.users = data;
    },
    LOGOUT(state, data) {
      state.currentUser = {
        isAdmin: false
      };
    },
    SET_USER_DATA(state, data) {
      if (!state.currentUser || state.currentUser.email !== data.email || state.currentUser.firstName !== data.firstName || state.currentUser.lastName !== data.lastName) {
        state.currentUser = data;
        state.currentUser.isAdmin = state.currentUser.roles === "ROLE_ADMIN";
      }
    }
  },
  actions: {
    fetchCurrentUser(context) {
      return axios
        .post(
          `${process.env.VUE_APP_BASE_URL}principal`,
          {},
          { withCredentials: true }
        )
        .then(response => {
          context.commit("SET_USER_DATA", response.data);
        })
        .catch(err => {
          context.commit("SHOW_MESSAGE", {
            type: 1,
            message:
              err && err.response && err.response.data
                ? err.response.data.message
                : null,
            error: err
          });
        });
    },
    handleLogout(context) {
      axios
        .post(`${process.env.VUE_APP_BASE_URL}logout`, {
          withCredentials: true
        })
        .then(a => {
          if (
            context.state.currentUser &&
            !context.state.currentUser.isInternalUser
          ) {
            let tenantId = context.state.currentUser.tenantId
              ? `${context.state.currentUser.tenantId}/`
              : "";
            window.location.href = `https://login.microsoftonline.com/${tenantId}oauth2/logout?post_logout_redirect_uri=${window.location.origin}`;
          } else {
            context.commit("LOGOUT");
            router.push({ path: "/login", query: { logout: "true" } });
          }
        });
    },
    handleInternalLogin(context, values) {
      return new Promise(resolve => {
        axios
          .post(
            `${process.env.VUE_APP_BASE_URL}login`,
            { username: values.username, password: values.password },
            { withCredentials: true }
          )
          .then(response => {
            context.commit("SET_USER_DATA", response.data);
            router.push("/");
            resolve();
          })
          .catch(err => {
            //handle error
            context.commit("SHOW_MESSAGE", {
              type: 1,
              message:
                err && err.response && err.response.data
                  ? err.response.data.message
                  : null,
              error: err
            });
          });
      });
    },
    handleRegisterSelf(context, values) {
      let requestBody = {
        email: values.email,
        password: btoa(values.password),
        firstName: values.firstName,
        lastName: values.lastName
      };
      return axios
        .post(`${process.env.VUE_APP_BASE_URL}register_self`, requestBody)
        .then(res => {
          let dialogType = 3;
          let dialogMessage = context.rootState.settings.isPreviewMode()
            ? "New Nash account has been successfully created. Check your email for confirmation"
            : "New Nash account has been successfully created. You will receive an email when your account is approved.";

          context.commit("SHOW_MESSAGE", {
            type: dialogType,
            message: dialogMessage
          });
          return true;
        })
        .catch(err => {
          context.commit("SHOW_MESSAGE", {
            type: 1,
            message:
              err && err.response && err.response.data
                ? err.response.data.message
                : null,
            error: err
          });
          return false;
        });
    },
    handleRegister(context, values) {
      let requestBody = {
        email: values.email,
        password: btoa(values.password),
        firstName: values.firstName,
        lastName: values.lastName,
        roles: values.roles,
        enabled: values.enabled
      };
      return axios
        .post(`${process.env.VUE_APP_BASE_URL}admin/register`, requestBody)
        .then(() => {
          context.commit("SHOW_MESSAGE", {
            type: 3,
            message: "New Nash account has been successfully created"
          });
          context.dispatch("getUsers");
          return true;
        })
        .catch(err => {
          context.commit("SHOW_MESSAGE", {
            type: 1,
            message:
              err && err.response && err.response.data
                ? err.response.data.message
                : null,
            error: err
          });
          return false;
        });
    },

    checkIfOldPasswordMatch(context, payload) {
      return axios
          .post(
          `${process.env.VUE_APP_BASE_URL}validate_old_password`,
               payload,
        {withCredentials: true}
          )
          .then(_ => {
            return true;
          })
          .catch(_ => {
            return false;
          })
    },
    handleUpdateUserPassword(context, payload) {
      return axios
          .post(
              `${process.env.VUE_APP_BASE_URL}change_user_password`,
              payload,
              {withCredentials: true}
          )
          .then(_ => {
            context.commit("SHOW_MESSAGE", {
              type: 3,
              message: "Password updated successfully",
            });
            return true;
          })
          .catch(err => {
            context.commit("SHOW_MESSAGE", {
              type: 1,
              message: err && err.response && err.response.data ? err.response.data.message : null,
              error: err
            });
            return false;
          })
    },

    validateSelfApprovalToken(context, values) {
      return axios
        .post(
          `${process.env.VUE_APP_BASE_URL}validate_self_approve_token?id=${values.id}&token=${values.token}`,
          {},
          { withCredentials: true }
        )
        .then(_ => {
          return true;
        })
        .catch(err => {
          context.commit("SHOW_MESSAGE", {
            type: 1,
            message: err && err.response && err.response.data ? err.response.data.message : null,
            error: err
          });
          return false;
        });
    },
    getUsers(context) {
      return axios
        .get(`${process.env.VUE_APP_BASE_URL}users`, { withCredentials: true })
        .then(users => {
          context.commit("SET_USERS", users.data);
        })
        .catch(err => {
          context.commit("SHOW_MESSAGE", {
            type: 1,
            message:
              err && err.response && err.response.data
                ? err.response.data.message
                : null,
            error: err
          });
        });
    },
    handleApprove(context, payload) {
      return axios
        .put(`${process.env.VUE_APP_BASE_URL}admin/approve/${payload.email}`, {
          withCredentials: true
        })
        .then(res => {
          let dialogType = 3;
          let dialogMessage = "User approved successfully";
          context.commit("SHOW_MESSAGE", {
            type: dialogType,
            message: dialogMessage
          });
          context.dispatch("getUsersToBeApproved");
          context.dispatch("getUsers");
          return true;
        })
        .catch(err => {
          context.commit("SHOW_MESSAGE", {
            type: 1,
            message:
              err && err.response && err.response.data
                ? err.response.data.message
                : null,
            error: err
          });
          return false;
        });
    },
    handleEditUser(context, user) {
      user.password = btoa(user.password);
      return axios
        .put(`${process.env.VUE_APP_BASE_URL}users`, user, {
          withCredentials: true
        })
        .then(() => {
          context.commit("SHOW_MESSAGE", {
            type: 3,
            message: "Account has been updated"
          });
          // if(user.isAdmin)
            context.dispatch("getUsers");
          return true;
        })
        .catch(err => {
          context.commit("SHOW_MESSAGE", {
            type: 1,
            message:
              err && err.response && err.response.data
                ? err.response.data.message
                : null,
            error: err
          });
          return false;
        });
    },
    handleDeleteUser(context, payload) {
      return axios
        .delete(`${process.env.VUE_APP_BASE_URL}admin/users/${payload.email}`, {
          withCredentials: true
        })
        .then(() => {
          context.commit("SHOW_MESSAGE", {
            type: 3,
            message: "User deleted successfully"
          });
          context.dispatch("getUsersToBeApproved");
          context.dispatch("getUsers");
          return true;
        })
        .catch(err => {
          context.commit("SHOW_MESSAGE", {
            type: 1,
            message:
              err && err.response && err.response.data
                ? err.response.data.message
                : null,
            error: err
          });
          return false;
        });
    },
    getUsersToBeApproved(context) {
      return axios
        .get(`${process.env.VUE_APP_BASE_URL}approval_users`, {
          withCredentials: true
        })
        .then(users => {
          context.commit("SET_APPROVAL_USERS", users.data);
          return true;
        })
        .catch(err => {
          context.commit("SHOW_MESSAGE", {
            type: 1,
            message:
              err && err.response && err.response.data
                ? err.response.data.message
                : null,
            error: err
          });
          return false;
        });
    },
    handleSendForgotPasswordEmail(context, payload) {
      return axios
        .post(
          `${process.env.VUE_APP_BASE_URL}reset_password`, payload
        )
        .then(() => {
          context.commit("SHOW_MESSAGE", {
            type: 3,
            message: "Check your email to get reset password link"
          });
          return true;
        })
        .catch(err => {
          context.commit("SHOW_MESSAGE", {
            type: 1,
            message:
              err && err.response && err.response.data
                ? err.response.data.message
                : null,
            error: err
          });
          return false;
        });
    },
    handleUpdateForgottenPassword(context, payload) {
      payload.password = btoa(payload.password);
      return axios
        .post(
          `${process.env.VUE_APP_BASE_URL}update_forgotten_password`,
          payload
        )
        .then(() => {
          context.commit("SET_SHOW_LOGIN_FORM", true);
          router.push({ path: "/login" });
          context.commit("SHOW_MESSAGE", {
            type: 3,
            message: "Password updated successfully"
          });
          return true;
        })
        .catch(err => {
          context.commit("SHOW_MESSAGE", {
            type: 1,
            message:
              err && err.response && err.response.data
                ? err.response.data.message
                : null,
            error: err
          });
          return false;
        });
    }
  }
};

import axios from 'axios';
import Vue from 'vue';

export default {
  state: {
    data: {
      selectedFile: null,
      selectedFolder: '',
      sas: {
          key: '',
          container: ''
      },
      blobDetails: {
        storageName: '',
        storageKey: '',
        containerName: '',
      },
      blobFiles: [],
      blobContainers: [],
    }
  },
    mutations: {
        SET_BLOB_FILES_AND_SAS(state, data) {
          state.data.sas = {
              key: data.sas,
              container: state.data.blobDetails.containerName
          };
          state.data.blobFiles = data.blobFiles.map(file => {
             file.fullPath = file.name;
             file.container = state.data.blobDetails.containerName;
             return file;
          });
        },
        SET_SELECTED_FOLDER(state, folder) {
            state.data.selectedFolder = folder;
        },
        SET_SAS_KEY(state, sas) {
            state.data.sas = sas
        },
        SET_SELECTED_FILE(state, file) {
            state.data.selectedFile = file;
        },
        CLEAR_BLOB_FILES(state) {
            state.data.blobFiles = []
        },
        SET_SELECTED_CONTAINER(state, item) {
            state.data.blobDetails.containerName = item;
        },
        SET_BLOB_DETAILS(state, item) {
            const clonedBlobDetails = Object.assign({}, state.data.blobDetails);
            clonedBlobDetails.storageName = item;
            state.data.blobDetails = clonedBlobDetails;
            state.data.blobDetails.storageName = item;
        }
    },
  getters: {
  },
  actions: {
    getSharedAccessSignature(context, containerName) {
        return axios.get(process.env.VUE_APP_BASE_URL + 'saskey/' + containerName )
            .then(res => {
                context.commit("SET_SAS_KEY", {key: res.data, container: containerName});
                return true
            })
            .catch(err => {
                context.commit("SHOW_MESSAGE", {
                    type: 1,
                    message: err && err.response && err.response.data ? err.response.data.message : null,
                    error: err
                });
                return false
            })
    },
      getSharedAccessSignatureWithoutSettingToStore(context, containerName) {
          return axios.get(process.env.VUE_APP_BASE_URL + 'saskey/' + containerName ).then(response => {
              return {container: containerName, key: response.data}
          })
          .catch(err => {
              context.commit("SHOW_MESSAGE", {
                  type: 1,
                  message: err && err.response && err.response.data ? err.response.data.message : null,
                  error: err
              });
              return false
          })
      },
    loadBlobStorageContainers(context) {
      return axios.get(process.env.VUE_APP_BASE_URL + "containers")
          .then( response => {
              if(response && response.status === 200) {
                  context.state.data.blobContainers = response.data;
                  return true;
              }
              return false;

          }).catch(err => {
            context.commit("SHOW_MESSAGE", {
              type: 1,
              message: err && err.response && err.response.data ? err.response.data.message : null,
              error: err
            });
            return false;
      })
    },
    loadAzureBlobData(context, d) {
        if (!context.state[d.id]) {
          Vue.set(context.state, d.id, {
              ...context.state.default
          });
        }
        d.containerName = context.state.data.blobDetails.containerName;
        axios.post(process.env.VUE_APP_BASE_URL + "blobs", d)
        .then((response) => {
          context.commit("SET_BLOB_FILES_AND_SAS", {sas: response.data.sas, blobFiles: response.data.blobFiles, accountDetails: d});
        })
        .catch((err) => {
            context.commit("SHOW_MESSAGE", {
                type: 1,
                message: err && err.response && err.response.data ? err.response.data.message : null,
                error: err
            });
        })
    }
  }
}

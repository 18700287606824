<template>
    <div class="flex row p-2 v-p-v-wrapper" :class="{maxHeightWrapper: processingData.intermediateProcessingStatus && processingData.intermediateProcessingStatus.length > 8}">
        <div class="w-65 nash-progress-bar">
            <span class="title">Video Processing Pipeline</span>
            <b-progress v-if="processingData.intermediateProcessingStatus && processingData.intermediateProcessingStatus.segments && processingData.intermediateProcessingStatus.segments.length > 0 && processingData.intermediateProcessingStatus.segments.length <= 8">
                <b-progress-bar
                        :class="{'in-progress': segment.status === 'not_started', 'blinking': segment.status === 'primary'}"
                        :id="'segment-processing-tooltip-target-' + segment.index"
                        class="nash-progress-bar-segment"
                        v-for="segment of processingData.intermediateProcessingStatus.segments"
                        :style="{cursor: segment && segment.data ? 'pointer' : 'unset'}"
                        :key="segment.index"
                        :value="segment.value"
                        :label="(segment.index + 1) + '' "
                        :variant="segment.status !== 'primary' ? (segment.status === 'stopped' ? 'danger' : segment.status) : ''">
                    <div  class="batch-separator" :class="{'first-batch-separator': segment.batchId === 0 }" v-if="segment.firstSegmentOfBatch && segment.batchId !== null && segment.batchId !== undefined">{{segment.batchId + 1}}</div>
                    <div class="pseudo-elm"></div>
                    <b-tooltip  custom-class="progress-tooltip" :target="'segment-processing-tooltip-target-' + segment.index" placement="bottom" triggers="click">
                        <segments-tooltip v-if="segment && segment.data" :tooltipId="'segment-processing-tooltip-target-' + segment.index" :type="1" :segment="segment"></segments-tooltip>
                    </b-tooltip>
                </b-progress-bar>
            </b-progress>
            <video-processing-grid v-if="processingData.intermediateProcessingStatus && processingData.intermediateProcessingStatus.segments && processingData.intermediateProcessingStatus.segments.length > 8" :segmentsData="processingData.intermediateProcessingStatus.segments"></video-processing-grid>
            <div class="no-data-text">
                <span v-if="!processingData.intermediateProcessingStatus || processingData.intermediateProcessingStatus.length === 0">No data available yet</span>
            </div>
        </div>
        <div class="w-15 post-processing-progress-bar">
            <span class="title">Summary</span>
            <b-progress :max="1" id="tracking-processing-tooltip" v-if="processingData.processingStatus">
                <b-progress-bar :value="1" :variant="processingData.processingStatus.tracking.status === 'stopped' ? 'danger' : processingData.processingStatus.tracking.status">
                    <div class="pseudo-elm"></div>
                </b-progress-bar>
            </b-progress>
            <b-tooltip v-if="processingData.processingStatus" :variant="processingData.processingStatus.tracking.status === 'stopped' ? 'danger' : processingData.processingStatus.tracking.status" custom-class="progress-tooltip" target='tracking-processing-tooltip' placement="bottom" triggers="click">
                <segments-tooltip tooltipId="tracking-processing-tooltip" :type="2" :processingData="processingData"></segments-tooltip>
            </b-tooltip>
        </div>
        <div class="w-15 post-processing-progress-bar">
            <span class="title">Post Analysis</span>
            <b-progress :max="3" id="post-processing-tooltip" v-if="processingData.postProcessingStatus">
                <b-progress-bar :value="processingData.postProcessingCompletion" :variant="processingData.postProcessingStatus.status === 'stopped' ? 'danger' : processingData.postProcessingStatus.status">
                    <div class="pseudo-elm"></div>
                </b-progress-bar>
            </b-progress>
            <b-tooltip v-if="processingData.postProcessingStatus"  custom-class="progress-tooltip" target='post-processing-tooltip' placement="bottom" triggers="click">
                <segments-tooltip tooltipId="post-processing-tooltip" :type="3" :processingData="processingData"></segments-tooltip>
            </b-tooltip>
        </div>
        <b-icon-x class="header-cancel-button" v-on:click="$emit('close')" variant="secondary"></b-icon-x>
    </div>
</template>

<script>
    import VideoProcessingGrid from '@/components/videoProcessingVisualizationComponents/VideoProcessingGrid.vue'
    import SegmentsTooltip from '@/components/videoProcessingVisualizationComponents/SegmentsTooltip.vue'
    import * as moment from 'moment';
    import * as momentDurationFormatSetup from "moment-duration-format";
    export default {
        name: "VideoProcessingVizualization.vue",
        components: {
           VideoProcessingGrid, SegmentsTooltip
        },
        data() {
            return {
                segmentIntervals: [],
            }
        },
        computed: {
            processingData() {
                return this.$store.state.pipelineConfigs.data.pipelineStatus;
            }
        },
        beforeDestroy(){
            this.segmentIntervals.forEach(segmentInterval => {
                clearInterval(segmentInterval);
            });
        },
        beforeMount() {
            momentDurationFormatSetup(moment);
            if(this.$store.state.videoPlayer.video.id)
                this.init();

            this.$root.$on('bv::tooltip::show', bvEvent => {
                if(bvEvent.target.id.indexOf('processing') !== -1){
                    this.$root.$emit('bv::hide::tooltip');
                    bvEvent.target.classList.add('active-segment');
                }
            });
            this.$root.$on('bv::tooltip::hide', bvEvent => {
                if(bvEvent.target.id.indexOf('processing') !== -1){
                    bvEvent.target.classList.remove('active-segment')
                }
            })
        },
        watch: {
          "$store.state.videoPlayer.video.id": function (newVal) {
            if(newVal){
                this.init();
            }
          }
        },
        methods: {
            init() {
                this.$root.$emit('bv::hide::tooltip');
                this.$store.dispatch('getPipelineStatus', this.$store.state.videoPlayer.video.id);
            },
        }

    }
</script>

<style lang="scss">
    .progress-tooltip{
        z-index: 211!important;
        .arrow::before {
            border-bottom-color: rgba(57, 60, 67, 1) !important;
        }
    }
    .maxHeightWrapper{
        height: 150px!important;
    }
    .v-p-v-wrapper{
        justify-content: space-between;
        background-color: rgba(57, 60, 67, 0.7);
        > div {
            margin-top: 10px;
        }
        .header-cancel-button{
            color: #CED6E0!important;
            font-size: 16px;
            border-radius: 5px;
            cursor: pointer;
        }
    }
    .w-15{
        width: 15%;
    }
    .w-65 {
        width: 65%;
    }
    .title{
        color: #CED6E0;
        font-size: 12px;
    }
    .no-data-text{
        color: #CED6E0;
        font-size: 12px;
    }
    .blinking{
        animation: blinkingText 5s infinite!important;
    }
    .blinking.active-segment{
        animation: none!important;
        background-color: #0560a7;
        border: #0D96FF solid 2px;
        border-radius: 5px;
    }
    @keyframes blinkingText{
        0%{
            background-color: #393C43;
        }
        30%{
            background-color: #0D96FF;
        }
        100%{
            background-color: #393C43;
        }
    }

    .in-progress{
        background-color: rgba(57, 60, 67, 1)!important;
        border-left: solid #4B5561 1px;
    }
    .nash-progress-bar-segment{
        border-left: solid #4B5561 1px;
        &:first-child {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            .pseudo-elm {
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
            }
        }
        &:last-child {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;

            .pseudo-elm {
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
            }
        }
    }
    .nash-progress-bar-segment:hover {
        .pseudo-elm{
            background-color: rgba(0, 0, 0, .2);
        }
    }
    .first-batch-separator{
        border-top-left-radius: 5px;
    }
    .batch-separator{
        width: 14px;
        font-size: 8px;
        line-height: 10px;
        text-align: center;
        background-color: grey;
        height: 10px;
        position: absolute;
        margin-top: -5px;
    }
    .pseudo-elm {
        height: 100%;
        margin: 3px;
        background-color: rgba(0, 0, 0, 0);
    }
.nash-progress-bar{
    .bg-danger{
        background-color: #FFDF3C!important;
        color: black;
    }

    .progress{
        border-radius: 8px;
        background-color: rgba(57, 60, 67, 0.7) !important;
        .active-segment {
            .pseudo-elm {
                background-color: rgba(0, 0, 0, .3) !important;
            }
        }
    }
}
.post-processing-progress-bar{
    .bg-not_started{
        background-color: rgba(57, 60, 67, 0.7) !important;
    }
    .pseudo-elm {
        border-radius: 8px;
    }
    .progress{
        background-color: rgba(57, 60, 67, 0.7) !important;
        border: solid #4B5561 1px;
        border-radius: 8px;
    }

    .active-segment {
        .pseudo-elm {
            background-color: rgba(0, 0, 0, .3) !important;
        }
    }
}

.post-processing-progress-bar:hover {
    cursor: pointer;
    .pseudo-elm {
        background-color: rgba(0, 0, 0, .2) !important;
    }
}


</style>

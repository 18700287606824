import axios from "axios";

export default {
    state: {
        analyzedVideoBehaviour: {},
        data: {
            analyzedVideos: {},
            analyzeRequestsSubscriptions: {}
        }
    },

    mutations: {
        SET_ANALYZED_VIDEO_BEHAVIOUR(state, payload) {
            state.analyzedVideoBehaviour = payload;
        },
        SET_ANALYZED_VIDEOS(state, payload) {
            payload.response.forEach(video => {
               state.data.analyzedVideos[video.originalFileName] = video.analyzedVideos.map(file => {
                   file.fullPath =  `https://${payload.request.blobDetails.storageName}.blob.core.windows.net/${payload.request.blobDetails.containerName}/${file.name}`;
                   return file;
               })
            });
        },
        UPDATE_ANALYZED_VIDEO(state, payload) {
            state.data.analyzedVideos[payload.response.originalFileName] = payload.response.analyzedVideos.map(file => {
                file.fullPath =  `https://${payload.request.blobDetails.storageName}.blob.core.windows.net/${payload.request.accountDetails.blobDetails.containerName}/${file.name}`;
                return file;
            })
        },
    },
    actions: {
        getAnalyzedVideoBehaviour(context, id) {
            const frameRate = context.rootState.videoPlayer.video.frameRate;
            function getDefaultDate(d) {
                let date = new Date(d);
                date.setHours(0, 0, 0, 0);
                return date;
            }
            function setStartTimeAndEndTime(now, startTime, endTime) {
                let startDate = getDefaultDate(now);
                let endDate = getDefaultDate(now);
                startDate.setMilliseconds(startTime * 1000 / frameRate);
                endDate.setMilliseconds((endTime * 1000/ frameRate ));
                return {
                    startTime: startDate, endTime: endDate
                }
            }
            function setEventsStartTimeAndEndTime(now, startTime, endTime) {
                let startDate = getDefaultDate(now);
                let endDate = getDefaultDate(now);
                startDate.setMilliseconds(startTime * 1000 / frameRate);
                endDate.setMilliseconds(endTime * 1000 / frameRate);
                return {
                    startTime: startDate, endTime: endDate
                }
            }

            return axios.get(process.env.VUE_APP_BASE_URL + "behaviour-metrics?analyzeId=" + id)
                .then(a => {
                        let spikeEvents = [];
                        let treewellEvents = [];
                        let surgeEvents = [];
                        let diveEvents = [];
                        let oscillationEvents = [];
                        let now = Date.now();
                        let bhData = a.data.sort((a,b) => a.startFrame - b.startFrame).map(a => {
                            let bhTime = setStartTimeAndEndTime(now, a.startFrame, a.endFrame);
                            a.startTime = bhTime.startTime;
                            a.endTime = bhTime.endTime;

                            let processedEvents = a.events.map(ev => {
                                let evTime = setEventsStartTimeAndEndTime(now, ev.startFrame, ev.endFrame);
                                ev.startTime = evTime.startTime;
                                ev.endTime = evTime.endTime;
                                return ev;
                            });
                            switch(a.behaviorType) {

                              case 'spike':           spikeEvents = spikeEvents.concat(processedEvents.filter(ev => ev.eventType === 'traffic_surge' || ev.eventType === 'traffic_dive'));
                                                      break;

                              case 'anti_spike':       treewellEvents = treewellEvents.concat(processedEvents.filter(ev => ev.eventType === 'traffic_surge' || ev.eventType === 'traffic_dive'));
                                                      break;

                              case 'surge':           surgeEvents = surgeEvents.concat(processedEvents.filter(ev => ev.eventType === 'traffic_surge'));
                                                      break;

                              case 'dive':            diveEvents = diveEvents.concat(processedEvents.filter(ev => ev.eventType === 'traffic_dive'));
                                                      break;

                              case 'oscillation':     oscillationEvents = oscillationEvents.concat(processedEvents.filter(ev => ev.eventType === 'traffic_inc' || ev.eventType === 'traffic_dec'));
                                                      break;

                            }
                            return a;
                        });
                        let oscillations = bhData.filter(a => a.behaviorType === 'oscillation').map(a => {a.behaviorLabel = 'Oscillation'; return a;});
                        let spikes = bhData.filter(a => a.behaviorType === 'spike').map(a => {a.behaviorLabel = 'Peak'; return a;});
                        let treewells = bhData.filter(a => a.behaviorType === 'anti_spike').map(a => {a.behaviorLabel = 'Valley'; return a;});
                        let surges = bhData.filter(a => a.behaviorType === 'surge').map(a => {a.behaviorLabel = 'Surge'; return a;});
                        let dives = bhData.filter(a => a.behaviorType === 'dive').map(a => {a.behaviorLabel = 'Dive'; return a;});
                        let processedData = {
                            behavior: {oscillations, spikes, treewells, surges, dives},
                            events: {spikeEvents, oscillationEvents, surgeEvents, diveEvents, treewellEvents}
                        };
                        context.commit("SET_ANALYZED_VIDEO_BEHAVIOUR", processedData);
                        return true;
                })
                .catch(err => {
                    context.commit("SHOW_MESSAGE", {
                        type: 1,
                        message: err && err.response && err.response.data ? err.response.data.message : null,
                        error: err
                    });
                    context.commit("SET_ANALYZED_VIDEO_BEHAVIOUR", {});
                    return false;
                })
        },
        getVideoCountMetricsPerSecond(context, id) {
            return axios.get(process.env.VUE_APP_BASE_URL + "object-count-per-second?analyzeId=" + id)
                .then(response => {
                    let processedData = response.data.map(items => {
                        items.day = items.secondNr;
                        items.count = items.objectCount;
                        return items;
                    });
                    //Here additional processing to make data ready for linechart
                    context.commit("SET_LINECHART_DATA", {values: processedData, duration: processedData.length});
                    return true
                })
                .catch(err => {
                    context.commit("SHOW_MESSAGE", {
                        type: 1,
                        message: err && err.response && err.response.data ? err.response.data.message : null,
                        error: err
                    });
                    return false
                })
        },
        getVideoCountMetrics(context, id) {
            return axios.get(process.env.VUE_APP_BASE_URL + "object-count-per-frame?analyzeId=" + id)
                .then(response => {
                    let processedData = response.data.map(items => {
                        items.day = items.second;
                        return items;
                    });
                    //Here additional processing to make data ready for linechart
                    let videoDuration = processedData.length > 0 ? Math.ceil(processedData[processedData.length - 1].day) : 0;
                    context.commit("SET_VIDEO_DURATION", videoDuration);
                    context.commit("SET_LINECHART_DATA", {values: processedData, duration: videoDuration});
                    return true
                })
                .catch(err => {
                    context.commit("SHOW_MESSAGE", {
                        type: 1,
                        message: err && err.response && err.response.data ? err.response.data.message : null,
                        error: err
                    });
                    context.commit("SET_VIDEO_DURATION", 0);
                    context.commit("SET_LINECHART_DATA", {values: [], duration: 0});
                    return false
                })
        }
    }
}

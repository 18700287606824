
export default {
  state: {
    panels: {
      showHeader: false,
      showLineChart: false,
      showTimeline: false,
      showSimilarityPanel: false,
      showAnomalyPanel: false,
      showVideoControls: false,
      showProgressMonitoring: false,
    },
    charts: {
      timelineChart: {
        resize: false
      }
    },
    manageAoi: {
      edit: null,
      delete: null
    }
  },
  mutations: {
    SHOW_HEADER(state, isVisible) {
      state.panels.showHeader = isVisible;
    },

    TOGGLE_LINE_CHART(state) {
      state.panels.showLineChart = !state.panels.showLineChart;
    },
    TOGGLE_TIMELINE(state) {
      state.panels.showTimeline = !state.panels.showTimeline;
    },
    TOGGLE_SIMILARITY_PANEL(state) {
      state.panels.showSimilarityPanel = !state.panels.showSimilarityPanel;
    },
    TOGGLE_ANOMALY_PANEL(state) {
      state.panels.showAnomalyPanel = !state.panels.showAnomalyPanel;
    },
    TOGGLE_PROGRESS_MONITORING(state) {
      state.panels.showProgressMonitoring = !state.panels.showProgressMonitoring;
    },
    SHOW_LINE_CHART(state, isVisible) {
      state.panels.showLineChart = isVisible;
    },
    SHOW_TIMELINE(state, isVisible) {
      state.panels.showTimeline = isVisible;
    },
    SHOW_SIMILARITY_PANEL(state, isVisible) {
      state.panels.showSimilarityPanel = isVisible;
    },
    SHOW_ANOMALY_PANEL(state, isVisible) {
      state.panels.showAnomalyPanel = isVisible;
    },
    SHOW_VIDEO_CONTROLS(state, isVisible) {
      state.panels.showVideoControls = isVisible;
    },
    SHOW_PROGRESS_MONITORING(state, isVisible) {
      state.panels.showProgressMonitoring = isVisible;
    },
    SET_RESIZE_TIMELINE(state, doResize) {
      state.charts.timelineChart.resize = doResize;
    },

    SHOW_ALL_PANELS(state, isVisible) {
      state.panels.showAnomalyPanel = isVisible;
      state.panels.showSimilarityPanel = isVisible;
      state.panels.showTimeline = isVisible;
      state.panels.showLineChart = isVisible;
      state.panels.showProgressMonitoring = isVisible;
    }
  },
  getters: {
  },
  actions: {
  }
}

<template>
    <div >
        <div :data-cy="segmentsProcessingTooltipCy+'_'+tooltipId" v-if="type === 1 && segment && segment.data" class="progress-tooltip-wrapper progress-tooltip-wrapper-big">
            <div class="progress-tooltip-row">
                <span class="tootltip-title">Analyzing Video</span>
                <b-icon-x class="header-cancel-button" v-on:click="$root.$emit('bv::hide::tooltip', tooltipId)" variant="secondary"></b-icon-x>
            </div>
            <div class="progress-tooltip-row">
                <span>Stage</span>
                <span>Duration</span>
            </div>
            <video-processing-visualization-row :rowCount="1 +'-'+ segment.index" title="Objects" type="segment" :segmentValue="segment.data.partialObjectDetectionStatus"></video-processing-visualization-row>
            <video-processing-visualization-row :rowCount="2 +'-'+ segment.index" title="Tracking" type="segment" :segmentValue="segment.data.partialTrackingStatus"></video-processing-visualization-row>
            <video-processing-visualization-row :rowCount="3 +'-'+ segment.index" title="Video" type="segment" :segmentValue="segment.data.partialPlaylistStatus" :overrideNotStarted="(segment.data.partialTrackingStatus.status === 'Finished' || segment.data.partialTrackingStatus.status === 'success') && (segment.data.partialPlaylistStatus.status === 'NotStarted' || segment.data.partialPlaylistStatus.status === 'not_started')"></video-processing-visualization-row>
            <video-processing-visualization-row :rowCount="4 +'-'+ segment.index" title="Behaviors" type="segment" :segmentValue="segment.data.partialBehaviorStatus"></video-processing-visualization-row>
            <video-processing-visualization-row :rowCount="5 +'-'+ segment.index" title="Anomalies" type="segment" :segmentValue="segment.data.partialAnomalyStatus"></video-processing-visualization-row>
            <video-processing-visualization-row :rowCount="6 +'-'+ segment.index" title="Similarities" type="segment" :segmentValue="segment.data.partialSimilarityStatus"></video-processing-visualization-row>

            <video-processing-vizualisation-footer type="segment" :segmentValue="segment.data"></video-processing-vizualisation-footer>
            <video-processing-vizualisation-footer title="Pipeline processing so far" :processedData="true" type="segment" :segmentValue="segment.data"></video-processing-vizualisation-footer>

            <div class="progress-tooltip-row progress-tooltip-footer-rows" v-if="segment.data.partitionsStatus">
                <span>Processed partitions</span>
                <span class="progress-tooltip-footer-value">{{segment.data.partitionsStatus.completedPartitions}} of {{segment.data.partitionsStatus.numberOfPartitions}}</span>
            </div>
            <div class="progress-tooltip-row progress-tooltip-footer-rows">
                <span>Segment {{segment.data.segmentId}}</span>
                <span class="progress-tooltip-footer-value">{{segment.videoInterval.from}} -> {{segment.videoInterval.to}}</span>
            </div>
            <div class="progress-tooltip-row progress-tooltip-footer-rows" v-if="segment.batchId !== null && segment.batchId !== undefined">
                <span>Batch {{segment.batchId + 1}}</span>
                <span class="progress-tooltip-footer-value ellipsis" v-if="segment.data.batchId" v-b-tooltip.hover :title="segment.data.batchId">{{segment.data.batchId.slice(0,8)}}<b-icon-files class="copy-button" v-on:click="copyToClipboard(segment.data.batchId)"></b-icon-files></span>
            </div>
        </div>

        <div v-if="type === 2 && processingData && processingData.processingStatus && processingData.processingStatus.tracking" class="progress-tooltip-wrapper-small progress-tooltip-wrapper">
            <div class="progress-tooltip-row">
                <span class="tootltip-title">Summary</span>
                <b-icon-x class="header-cancel-button" v-on:click="$root.$emit('bv::hide::tooltip', tooltipId)" variant="secondary"></b-icon-x>
            </div>
            <video-processing-vizualisation-footer style="margin-bottom: 0!important;margin-top: 0!important;" :segmentValue="processingData.processingStatus"></video-processing-vizualisation-footer>
            <b-button v-if="showTrackingButton" v-on:click="stopEnhancement()" class="save-button stop-enhancement-button">Stop Enhancement</b-button>
        </div>

        <div v-if="type === 3 && processingData && processingData.postProcessingStatus" class="progress-tooltip-wrapper-medium progress-tooltip-wrapper">
            <div class="progress-tooltip-row">
                <span class="tootltip-title">Analyzing Video</span>
                <b-icon-x class="header-cancel-button" v-on:click="$root.$emit('bv::hide::tooltip', tooltipId)" variant="secondary"></b-icon-x>
            </div>
            <div class="progress-tooltip-row">
                <span>Stage</span>
                <span>Duration</span>
            </div>
            <video-processing-visualization-row rowCount="8" title="Behaviors" :segmentValue="processingData.postProcessingStatus.behavior"></video-processing-visualization-row>
            <video-processing-visualization-row rowCount="9" title="Anomalies" :segmentValue="processingData.postProcessingStatus.anomaly"></video-processing-visualization-row>
            <video-processing-visualization-row rowCount="10" title="Similarities" :segmentValue="processingData.postProcessingStatus.similarity"></video-processing-visualization-row>
            <video-processing-vizualisation-footer :type="'segment'" :segmentValue="processingData.postProcessingStatus"></video-processing-vizualisation-footer>

        </div>
    </div>
</template>

<script>
    import VideoProcessingVizualisationFooter from '@/components/videoProcessingVisualizationComponents/VideoProcessingVizualisationFooter.vue'
    import VideoProcessingVisualizationRow from '@/components/videoProcessingVisualizationComponents/VideoProcessingVisualizationRow.vue'
    import Constants from "@/helpers/constants"
    export default {
        name: "SegmentsTooltip",
        components: {
            VideoProcessingVisualizationRow, VideoProcessingVizualisationFooter
        },
        data(){
            return{
               segmentsProcessingTooltipCy: Constants.SEGMENTS_PROCESSING_TOOLTIP_CY
            };
        },
        props: {
            tooltipId: String,
            segment: Object,
            processingData: Object,
            type: Number,
        },
        computed: {
            showTrackingButton(){
                return this.$store.state.pipelineConfigs.showStopTrackingButton;
            }
        },
        methods: {
            copyToClipboard(batchId) {
                const el = document.createElement('textarea');
                el.value = batchId;
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
            },
            stopEnhancement(){
                this.$store.dispatch("stopTrackingEnhancement", this.processingData.pipelineId).then(res => {
                    this.$store.commit('SHOW_STOP_TRACKING_BUTTON', false);
                });
            }
        }
    }
</script>

<style lang="scss">
    .progress-tooltip{
        z-index: 99999999999!important;
        padding: 0.4rem 0!important;
        opacity: 1!important;

        .bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
            padding: 0.4rem 0!important;
        }

        .tooltip-inner{
            max-width: unset!important;
            background-color: rgba(33,44,56, 1) !important;
        }
        .progress-tooltip-wrapper-small {
            height: 180px!important;
            .progress-tooltip-footer-rows {
                margin-top: 10px;
                color: white!important;
            }
            .progress-tooltip-row {
              border-bottom: none!important;
            }

              .stop-enhancement-button{
                font-size: 14px;
                line-height: 10px;
                height: 30px;
                margin-top: auto;
                border: none;
            }
        }
        .progress-tooltip-wrapper-medium {
            height: 200px!important;
            .progress-tooltip-footer-rows{
                margin-top: 10px!important;

            }
        }
        .progress-tooltip-wrapper-big {
            height: 350px!important;
        }
        .progress-tooltip-wrapper{
            width: 250px!important;
            padding: 10px;
            display: flex;
            flex-direction: column;
            .header-cancel-button{
                color: #CED6E0!important;
                margin-bottom: 10px;
                font-size: 16px;
                border-radius: 5px;
                cursor: pointer;
            }
            .header-cancel-button:hover{
                background-color: white;
                color: #4B5561!important;
            }
            .tootltip-title {
                text-align: left;
                margin-bottom: 10px;
            }
            .progress-tooltip-footer-rows{
                border-bottom: none!important;
                min-height: 0!important;
                color: #9098A3;
            }
            .progress-tooltip-row {
                min-height: 32px;
                border-bottom: solid 1px #353C45;
                align-items: center;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                .progress-tooltip-footer-value{
                    color: #CED6E0;
                    max-width: 150px;
                }
                .copy-button{
                    margin-left: 5px;
                    cursor: pointer;
                }

                .copy-button:hover{
                    color: var(--primary-color);
                }
                .partitions-info-circle{
                    margin-right: 10px;
                    cursor: pointer;
                }
                .dot{
                    margin-left: 2px;
                }
                .check-circle{
                    width: 16px;
                    height: 16px;
                    vertical-align: -0.3em;
                }
                .row-text{
                    margin-left: 10px;
                }
            }
            .progress-tooltip-footer{
                margin-top: auto;
                text-align: left;
            }
        }
    }

</style>

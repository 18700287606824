import axios from 'axios';
import * as moment from 'moment';
import * as momentDurationFormatSetup from "moment-duration-format";

export default {
  state: {
    pipelineStatuses: [ "success", "danger", "stopped", "primary", 'not_started'],
    pipelinesSubscription: null,
    showStopTrackingButton: false,
    data: {
      pipelineConfigs: [],
      pipelineStatus: {},
    }
  },
  mutations: {
    SHOW_STOP_TRACKING_BUTTON(state, shouldShow) {
      state.showStopTrackingButton = shouldShow;
    },

    SET_PIPELINE_CONFIGS(state, data) {
      state.data.pipelineConfigs = data;
    },

    SET_PIPELINE_STATUS(state, payload) {
      momentDurationFormatSetup(moment);

      if(payload.data && payload.data.pipelineId) {
        payload.data.postProcessingCompletion = Object.keys(payload.data.postProcessingStatus).filter(key => {
          return (key === 'behavior' || key  === 'anomaly' || key === 'similarity') &&(payload.data.postProcessingStatus[key].status === 'Finished' || payload.data.postProcessingStatus[key].status === 'InProgress')
        }).length;
        payload.data.postProcessingStatus.status = payload.context.getters.getProcessingStatus(payload.data.postProcessingStatus, payload.context);
        payload.data.processingStatus.tracking.status = payload.context.getters.getProcessingStatus(payload.data.processingStatus.tracking, payload.context);

        if(payload.data.intermediateProcessingStatus.status === 'Failed' && payload.data.processingStatus.status === 'InProgress') {
          state.showStopTrackingButton = true;
        }


        payload.data.processingStatus.status = payload.context.getters.getProcessingStatus(payload.data.processingStatus, payload.context);


        // Remove next 5 line if we agree that total is not needed as segments are processed in parallel
        let bDuration = moment.duration(payload.data.postProcessingStatus.behavior.latency, 'milliseconds');
        let odDuration = moment.duration(payload.data.postProcessingStatus.anomaly.latency, 'milliseconds');
        let sDuration = moment.duration(payload.data.postProcessingStatus.similarity.latency, 'milliseconds');
        payload.data.postProcessingStatus.latency = JSON.stringify(moment.duration(bDuration + odDuration + sDuration, 'milliseconds')).replace('"', "").replace('"', "");
        // Stop removing

        payload.data.intermediateProcessingStatus.status = state.pipelineStatuses[payload.context.getters.getStatusIndex(payload.data.intermediateProcessingStatus.status)];
        let totalSegments = payload.data.intermediateProcessingStatus.segments.length;
        let batches = [];
        payload.data.intermediateProcessingStatus.segments = payload.data.intermediateProcessingStatus.segments.map((segment, index) => {
          let firstSegmentOfBatch = false;
          if(segment.batchId && batches.indexOf(segment.batchId) === -1) {
            batches.push(segment.batchId);
            firstSegmentOfBatch = true;
          }
          return {
            index: index,
            batchId: segment.batchId ? batches.indexOf(segment.batchId) : null,
            totalInterval: moment.duration(segment.processingLatency, 'seconds').format('h[h] mm[m] ss[s]'),
            videoInterval: {
              from: moment.duration(segment.startSecond, 'seconds').format('h[h] mm[m] ss[s]'),
              to: moment.duration(segment.endSecond, 'seconds').format('h[h] mm[m] ss[s]')
            },
            firstSegmentOfBatch: firstSegmentOfBatch,
            status: state.pipelineStatuses[payload.context.getters.getStatusIndex(segment.status)],
            data: segment,
            value: 100 / totalSegments,
          }
        });

        if(totalSegments > payload.data.intermediateProcessingStatus.segments.length) {
          for(let i = payload.data.intermediateProcessingStatus.segments.length; i < totalSegments; i++) {
            payload.data.intermediateProcessingStatus.segments.push({
              index: i,
              status: state.pipelineStatuses[3],
              data: null,
              value: 100 / totalSegments,
            });
          }
        }
        state.data.pipelineStatus = payload.data;
      } else  {
        state.data.pipelineStatus = {}
      }
    },

    UPDATE_PIPELINE_STATUS(state, payload) {
      if(payload.data) {
        let keys = Object.keys(payload.data);
        if(keys.length > 0){
          const id = +keys[0];
          state.data.pipelineConfigs = state.data.pipelineConfigs.map(config => {
            if(config.id === id){
              if(payload.data[id]){
                config.scene.analysisState = payload.data[id];
              }
            }
            return config;
          })
        }

      }

    },

    CLEAR_PIPELINES_SUBSCRIPTIONS(state) {
      if(state.pipelinesSubscription){
        state.pipelinesSubscription.unsubscribe();
        state.pipelinesSubscription = null;
      }
    }
  },
  getters: {
    getStatusIndex: (state) => (sts) => {
      switch (sts) {
        case 'Finished': return 0;
        case 'Failed': return 1;
        case 'Stopped': return 2;
        case 'InProgress' : return 3;
        default: return 4;
      }
    },
    getProcessingStatus: (state) => (processingValue, context) => {
      return state.pipelineStatuses[context.getters.getStatusIndex(processingValue.status)];
    }
  },
  actions: {

    getVideoOnDemand(context, id) {
      return axios.post(process.env.VUE_APP_BASE_URL + 'render-partial-video?pipelineId=' + id)
          .then(response => {
            if (response && response.status === 200) {
              return true;
            }
            return false;

          })
          .catch(err => {
            context.commit("SHOW_MESSAGE", {
              type: 0,
              message: 'Partial video rendering already in process',
              error: err
            });
            // context.commit("SET_PIPELINE_STATUS", {});
            return false;
          });
    },

    getPipelineStatus(context, id) {
      return axios.get(process.env.VUE_APP_BASE_URL + 'pipeline-stage-status?pipelineId=' + id)
          .then(response => {
            if (response && response.status === 200) {
              context.commit("SET_PIPELINE_STATUS", {context: context, data: response.data});
              return true;
            }
            return false;

          })
          .catch(err => {
            context.commit("SHOW_MESSAGE", {
              type: 1,
              message: err && err.response && err.response.data ? err.response.data.message : null,
              error: err
            });
            context.commit("SET_PIPELINE_STATUS", {});
            return false;
          });
    },
    getPipelineConfigs(context) {
      axios.get(process.env.VUE_APP_BASE_URL + 'pipeline-configs')
        .then(response => {
          if (response && response.status === 200) {
            context.commit("SET_PIPELINE_CONFIGS", response.data)
            context.dispatch('subscribeToPipelinesStatus');
          }
        })
          .catch(err => {
            context.commit("SHOW_MESSAGE", {
              type: 1,
              message: err && err.response && err.response.data ? err.response.data.message : null,
              error: err
        });
      });
    },
    deletePipelineConfig(context, id) {
      axios.delete(process.env.VUE_APP_BASE_URL + 'pipeline-configs/' + id)
        .then(response => {
          if (response && response.status === 200) {
            context.commit("SHOW_MESSAGE", {
              type: 3,
              message: "Pipeline deleted successfully"
            });
            
            if(context.rootState.videoPlayer.video && context.rootState.videoPlayer.video.id === id) {
              context.commit('CLEAR_VIDEO_OBJECT');
              context.dispatch('clearSubscriptions');
              context.commit('SHOW_ALL_PANELS', false);
              context.commit("SET_SIMILARITY_DATA", {});
              context.commit('SET_ANOMALY_DATA', {});
            }
            context.commit("SET_PIPELINE_CONFIGS", context.state.data.pipelineConfigs.filter(pipeline => pipeline.id !== id))
          }
        }).catch(err => {
            context.commit("SHOW_MESSAGE", {
              type: 1,
              message: err && err.response && err.response.data ? err.response.data.message : null,
              error: err
            });
      });
    },
    stopTrackingEnhancement(context, id) {

      return axios.post(process.env.VUE_APP_BASE_URL + 'enhancement-tracking/stop/?pipelineId=' + id)
            .catch(err => {
              context.commit('SHOW_STOP_TRACKING_BUTTON', false);
              context.commit("SHOW_MESSAGE", {
                type: 1,
                message: err && err.response && err.response.data ? err.response.data.message : null,
                error: err
              });
        })
    },
    subscribeToPipelinesStatus(context) {
        context.commit('CLEAR_PIPELINES_SUBSCRIPTIONS');
        if(context.rootState.websocket.isConnected) {
          context.state.pipelinesSubscription = this.stompClient.subscribe(`${process.env.VUE_APP_BASE_WS_URL}/pipelinesStatus`, (response) => {
            context.commit('UPDATE_PIPELINE_STATUS', JSON.parse(response.body))
          })
        }
    },
  }
}

<template>
  <div class="app">
    <header v-if="showHeader === true" class="header">
      <div class="header-left">
        <Logo clickable></Logo>
  
        <div :data-cy="headerRightCy" v-if="width <= 1200" class="header-right">
          <!--When provisioning will be enabled and we'll have admin users, above div should be replaced with the one below-->
          <!--        <div class="h5 mb-2 pull-right" style="margin-left: 20px;" v-if="$store.state.users.currentUser && $store.state.users.currentUser.roles && $store.state.users.currentUser.roles.indexOf('ROLE_ADMIN') !== -1" v-on:click="$router.push('/settings')"><b-icon-gear></b-icon-gear></div>-->
          <div :data-cy="rightOptionNavButtons" class="flex-row flex-end">

            <div class="nav-button" v-if="$store.state.videoPlayer.video.src"><b-button :class="{pressedicon:showTimeline === true}" :disabled="$router.currentRoute.path !== '/' || $store.state.videoPlayer.video.isOriginalPlayed" class="nav-b-button"><b-icon-soundwave v-b-tooltip.hover :title="$router.currentRoute.path !== '/' ||$store.state.videoPlayer.video.isOriginalPlayed ? ($router.currentRoute.path !== '/' ? 'Not available from workspaces' : 'Not available for original video') : 'Events and Behaviors'" v-on:click="toggleTimeline()"></b-icon-soundwave></b-button></div>
            <div class="nav-button" v-if="$store.state.videoPlayer.video.src"><b-button :class="{pressedicon:showAnomalyPanel === true}" :disabled="$router.currentRoute.path !== '/' || $store.state.videoPlayer.video.isOriginalPlayed" class="nav-b-button"><b-icon-circle-square v-b-tooltip.hover :title="$router.currentRoute.path !== '/' || $store.state.videoPlayer.video.isOriginalPlayed ? ($router.currentRoute.path !== '/' ? 'Not available from workspaces' : 'Not available for original video') : 'Anomaly Detection'" v-on:click="toggleAnomalyPanel()"></b-icon-circle-square></b-button></div>
            <div class="nav-button" v-if="$store.state.videoPlayer.video.src"><b-button :class="{pressedicon:showSimilarityPanel === true}" :disabled="$router.currentRoute.path !== '/' || $store.state.videoPlayer.video.isOriginalPlayed" class="nav-b-button"><b-icon-arrow-left-right v-b-tooltip.hover :title="$router.currentRoute.path !== '/' || $store.state.videoPlayer.video.isOriginalPlayed ? ($router.currentRoute.path !== '/' ? 'Not available from workspaces' : 'Not available for original video') : 'Similar Workspaces'" v-on:click="toggleSimilarityPanel()"></b-icon-arrow-left-right></b-button></div>
            <div class="nav-button" v-if="$store.state.videoPlayer.video.src"><b-button :class="{pressedicon:showLineChart === true}" :disabled="$router.currentRoute.path !== '/' || $store.state.videoPlayer.video.isOriginalPlayed" class="nav-b-button"><b-icon-graph-up v-b-tooltip.hover :title="$router.currentRoute.path !== '/' || $store.state.videoPlayer.video.isOriginalPlayed ? ($router.currentRoute.path !== '/' ? 'Not available from workspaces' : 'Not available for original video') : 'Object Detection Graph'" v-on:click="toggleLineChart()"></b-icon-graph-up></b-button></div>
            <div v-if="$store.state.videoPlayer.video.src" class="nav-button divider-vertical"></div>
<!--            <div class="nav-button" :class="{'disabled-nav-btn': $store.state.settings.isPreviewMode() || ($store.state.users.currentUser && !$store.state.users.currentUser.isAdmin)}" v-b-tooltip.hover :title="$store.state.settings.isPreviewMode() ? 'This option is not available in preview mode': ($store.state.users.currentUser && !$store.state.users.currentUser.isAdmin) ? 'This option is available only for admins':'Settings'" v-on:click="goToSettings()"><b-icon-gear></b-icon-gear></div>-->
            <div :data-cy="usersViewCy" class="nav-button" v-if="!$store.state.settings.isPreviewMode() && $store.state.users.currentUser" v-b-tooltip.hover="'Users'" v-on:click="goToUsers()"><b-icon-people></b-icon-people></div>
            <div class="nav-button" v-if="!$store.state.settings.isPreviewMode()" v-b-tooltip.hover :title="'Settings'" v-on:click="goToSettings()"><b-icon-gear></b-icon-gear></div>
            <div class="nav-button divider-vertical" ></div>
            <b-dropdown right :toggle-class="$refs.editProfileDropdown && $refs.editProfileDropdown.visible ? 'open' : ''" ref="editProfileDropdown" class="user-name" id="edit-profile-2" v-if="$store.state.users.currentUser">
              <template v-slot:button-content>
                  <b-icon-person></b-icon-person>
                  {{$store.state.users.currentUser.firstName}} {{$store.state.users.currentUser.lastName}}
              </template>
              <b-dropdown-item v-if="$store.state.users.currentUser.isInternalUser" v-on:click="editProfile()">
                <div class="dropdown-item-row flex row">
                  <b-icon-pencil-square></b-icon-pencil-square>Edit profile
                </div>
              </b-dropdown-item>
              <b-dropdown-divider v-if="$store.state.users.currentUser.isInternalUser"></b-dropdown-divider>
              <b-dropdown-item v-if="$store.state.users.currentUser.isInternalUser" v-on:click="changeUserPassword()">
                <div class="dropdown-item-row flex row">
                  <b-icon-lock></b-icon-lock>Change Password
                </div>
              </b-dropdown-item>
              <b-dropdown-divider v-if="$store.state.users.currentUser.isInternalUser"></b-dropdown-divider>
              <b-dropdown-item v-on:click="logout()">
                <div class="dropdown-item-row flex row">
                  <b-icon-box-arrow-right></b-icon-box-arrow-right>Logout
                </div>
              </b-dropdown-item>
            </b-dropdown>
          </div>

        </div>
      </div>
      <div :data-cy="headerCenterCy" class="header-center">
        <b-button  :data-cy="workspaceButtonCy" class="transparent-button" v-b-tooltip.hover title="Create new workspace or manage existing workspaces" v-on:click="goToManageVideos()">{{workspaceButtonName}}</b-button>
        <b-button class="split-button transparent-button play-button" style="padding: 0!important;width: 250px;"
                  v-if="$store.state.videoPlayer.video.src && $router.currentRoute.path === '/'">
          <div class="flex row no-wrap h-100 align-items-center">
            <div v-if="isAnySegmentDone && processingStatus !== 'success'" :id="'video-on-demand_' + $store.state.videoPlayer.video.pipelineName" v-b-tooltip="'Start intermediate video creation job for in-progress workspace'" class="edit-pencil-wrapper" v-on:click="getVideoOnDemand">
              <b-icon-columns-gap disabled="false"></b-icon-columns-gap>
            </div>
            <span
                v-bind:disabled="!$store.state.videoPlayer.video.analysisSrc"
                v-on:click="toggleVideoSrc()"
                class="video-name-button">Switch to {{$store.state.videoPlayer.video.isOriginalPlayed ? 'Analyzed' : 'Original' }} Video</span>
          </div>
        </b-button>
        <b-button :data-cy="editButtonCy" style="padding: 0!important;" class="split-button transparent-button  progress-bar-button play-button" v-if="$store.state.videoPlayer.video.src && $router.currentRoute.path === '/'" v-b-tooltip.noninteractive="$store.state.videoPlayer.video.pipelineName">
          <div class="flex row no-wrap h-100 align-items-center">

            <div v-if="$store.state.videoPlayer.video.pipeline" :id="'pipeline-details-info_' + $store.state.videoPlayer.video.pipelineName" class="edit-pencil-wrapper" v-on:click="editPipeline">
              <b-icon-pencil></b-icon-pencil>
            </div>

            <b-tooltip triggers="hover" custom-class="pipeline-info-tooltip" :target="'pipeline-details-info_' + $store.state.videoPlayer.video.pipelineName">
              <pipeline-info-tooltip :showTitleDetails="true" :pipeline="$store.state.videoPlayer.video.pipeline"></pipeline-info-tooltip>
            </b-tooltip>
            <div class="progress-bar-button-name" v-on:click="onShowProgressVizualisationClick">
              <b-spinner v-b-tooltip="'Analysis in progress'" v-if="(intermediateProcessingStatus !== 'success' && intermediateProcessingStatus !== 'danger') || processingStatus === 'primary' || postProcessingStatus === 'primary'" variant="primary" small></b-spinner>
              <span class="ellipsis progress-bar-button-text">{{$store.state.videoPlayer.video.pipelineName}}</span>
              <b-icon-play-fill :style="{transform: showProgressContainer ? 'rotate(-90deg)' : 'rotate(90deg)', color:showProgressContainer ? '#0A78CC' : 'white' }"></b-icon-play-fill>
            </div>
            <b-badge v-if="$store.state.videoPlayer.partialPlaylistEvent" id="refreshBadge" v-on:click="refreshPartialVideoFlag($event)" class="progress-bar-icon"><b-icon-arrow-clockwise></b-icon-arrow-clockwise></b-badge>
          </div>
        </b-button>

      </div>
      <div v-if="width > 1200" class="header-right">
        <!--When provisioning will be enabled and we'll have admin users, above div should be replaced with the one below-->
        <!--        <div class="h5 mb-2 pull-right" style="margin-left: 20px;" v-if="$store.state.users.currentUser && $store.state.users.currentUser.roles && $store.state.users.currentUser.roles.indexOf('ROLE_ADMIN') !== -1" v-on:click="$router.push('/settings')"><b-icon-gear></b-icon-gear></div>-->
        <div class="flex-row flex-end">
          <div class="nav-button" v-if="$store.state.videoPlayer.video.src"><b-button :class="{pressedicon:showTimeline === true}" :disabled="$router.currentRoute.path !== '/' || $store.state.videoPlayer.video.isOriginalPlayed" class="nav-b-button"><b-icon-soundwave v-b-tooltip.hover :title="$router.currentRoute.path !== '/' || $store.state.videoPlayer.video.isOriginalPlayed ? ($router.currentRoute.path !== '/' ? 'Not available from workspaces' : 'Not available for original video') : 'Events and Behaviors'" v-on:click="toggleTimeline()"></b-icon-soundwave></b-button></div>
          <div class="nav-button" v-if="$store.state.videoPlayer.video.src"><b-button :class="{pressedicon:showAnomalyPanel === true}" :disabled="$router.currentRoute.path !== '/' || $store.state.videoPlayer.video.isOriginalPlayed" class="nav-b-button"><b-icon-circle-square v-b-tooltip.hover :title="$router.currentRoute.path !== '/' || $store.state.videoPlayer.video.isOriginalPlayed ? ($router.currentRoute.path !== '/' ? 'Not available from workspaces' : 'Not available for original video') : 'Anomaly Detection'" v-on:click="toggleAnomalyPanel()"></b-icon-circle-square></b-button></div>
          <div class="nav-button" v-if="$store.state.videoPlayer.video.src"><b-button :class="{pressedicon:showSimilarityPanel === true}" :disabled="$router.currentRoute.path !== '/' || $store.state.videoPlayer.video.isOriginalPlayed" class="nav-b-button"><b-icon-arrow-left-right v-b-tooltip.hover :title="$router.currentRoute.path !== '/' || $store.state.videoPlayer.video.isOriginalPlayed ? ($router.currentRoute.path !== '/' ? 'Not available from workspaces' : 'Not available for original video') : 'Similar Workspaces'" v-on:click="toggleSimilarityPanel()"></b-icon-arrow-left-right></b-button></div>
          <div class="nav-button" v-if="$store.state.videoPlayer.video.src"><b-button :class="{pressedicon:showLineChart === true}" :disabled="$router.currentRoute.path !== '/' || $store.state.videoPlayer.video.isOriginalPlayed" class="nav-b-button"><b-icon-graph-up v-b-tooltip.hover :title="$router.currentRoute.path !== '/' || $store.state.videoPlayer.video.isOriginalPlayed ? ($router.currentRoute.path !== '/' ? 'Not available from workspaces' : 'Not available for original video') : 'Object Detection Graph'" v-on:click="toggleLineChart()"></b-icon-graph-up></b-button></div>

          <div v-if="$store.state.videoPlayer.video.src" class="nav-button divider-vertical" ></div>
<!--          <div class="nav-button" :class="{'disabled-nav-btn': $store.state.settings.isPreviewMode() || ($store.state.users.currentUser && !$store.state.users.currentUser.isAdmin)}" v-b-tooltip.hover :title="$store.state.settings.isPreviewMode() ? 'This option is not available in preview mode': ($store.state.users.currentUser && !$store.state.users.currentUser.isAdmin) ? 'This option is available only for admins':'Settings'" v-on:click="goToSettings()"><b-icon-gear></b-icon-gear></div>-->
          <div class="nav-button" v-if="!$store.state.settings.isPreviewMode() && $store.state.users.currentUser " v-b-tooltip.hover="'Users'" v-on:click="goToUsers()"><b-icon-people></b-icon-people></div>
          <div class="nav-button" v-if="!$store.state.settings.isPreviewMode()" v-b-tooltip.hover="'Settings'" v-on:click="goToSettings()"><b-icon-gear></b-icon-gear></div>
          <div class="nav-button divider-vertical"></div>
          <b-dropdown right :toggle-class="$refs.editProfileDropdown && $refs.editProfileDropdown.visible ? 'open' : ''"  ref="editProfileDropdown"  class="user-name" id="edit-profile-2" v-if="$store.state.users.currentUser">
            <template v-slot:button-content>
                <b-icon-person></b-icon-person>
                {{$store.state.users.currentUser.firstName}} {{$store.state.users.currentUser.lastName}}
            </template>
            <b-dropdown-item v-if="$store.state.users.currentUser.isInternalUser" v-on:click="editProfile()">
              <div class="dropdown-item-row flex row">
                <b-icon-pencil-square></b-icon-pencil-square>Edit profile
              </div>
            </b-dropdown-item>
            <b-dropdown-divider v-if="$store.state.users.currentUser.isInternalUser"></b-dropdown-divider>
            <b-dropdown-item v-if="$store.state.users.currentUser.isInternalUser" v-on:click="changeUserPassword()">
              <div class="dropdown-item-row flex row">
                <b-icon-lock></b-icon-lock>Change Password
              </div>
            </b-dropdown-item>
            <b-dropdown-divider v-if="$store.state.users.currentUser.isInternalUser"></b-dropdown-divider>
            <b-dropdown-item v-on:click="logout()">
              <div class="dropdown-item-row flex row">
                <b-icon-box-arrow-right></b-icon-box-arrow-right>Logout
              </div>
            </b-dropdown-item>
          </b-dropdown>

        </div>

      </div>
    </header>
    <div v-if="$router.currentRoute.path === '/' && $store.state.videoPlayer.video.src && showProgressContainer" class="vizualization-wrapper">
      <videoProcessingVizualization @close="closeProgressVizualisationClick()"></videoProcessingVizualization>
    </div>
    <Message />
    <user-modal
            type="edit"
            title="Edit User Account"
            :userObj="$store.state.users.currentUser"
            :isEdit="true"
            ref="userModal"
    ></user-modal>

    <change-password-modal ref="changePasswordModal"></change-password-modal>
    <router-view></router-view>
    <b-overlay z-index="999999999" no-wrap :variant="'dark'" :spinner-variant="'light'" opacity="0.5" blur="3px"  :show="$store.state.showLoader" >
    </b-overlay>
  </div>
</template>

<script>
import interceptorsSetup from "@/helpers/httpInterceptor";
import Message from "@/components/shared/Message.vue";
import VideoProcessingVizualization from "@/components/VideoProcessingVizualization.vue";
import Logo from "@/components/shared/Logo.vue";
import PipelineInfoTooltip from "@/components/shared/PipelineInfoTooltip.vue";
import UserModal from "@/components/users/UserModal.vue";
import ChangePasswordModal from "@/components/users/ChangePasswordModal.vue"
import Constants from "@/helpers/constants"

interceptorsSetup();

export default {
  name: 'app',
  components: {PipelineInfoTooltip, UserModal, Message, VideoProcessingVizualization, Logo, ChangePasswordModal},
  data() {
    return {
      width: null,

      headerRightCy: Constants.HEADER_RIGHT_CY,
      rightOptionNavButtons: Constants.RIGHT_OPTION_NAV_BUTTONS_CY,
      usersViewCy: Constants.USERS_VIEW_CY,
      headerCenterCy: Constants.HEADER_CENTER_CY,
      workspaceButtonCy: Constants.WORKSPACE_BUTTON_CY,
      workspaceButtonName: Constants.WORKSPACE_BUTTON_NAME,

      editButtonCy: Constants.EDIT_BUTTON_CY
    }
  },
  watch: {
    "$store.state.websocket.isConnected": function(newVal) {
        if (newVal) {
            const subs = this.$store.state.manageAoi.data.analyzeRequestsSubscriptions;
            if (subs) {
                Object.keys(subs).forEach(key => {
                  this.$store.dispatch('subscribeToWebsocketTopicOfAnalysedVideo', key)
                })
            }
            if(this.$route.name === 'pipelines' || this.$route.name === 'preview'){
              this.$store.dispatch("subscribeToPipelinesStatus");
            }
            this.$store.dispatch('subscribeToErrorEvents', {email: this.$store.state.users.currentUser.email, modal: this.$bvModal});
        }
    },
    "$store.state.users.currentUser": function (newVal) {
      if(newVal && newVal.email){
        this.$store.dispatch("getBlobStorageDetails");
        this.$store.dispatch("connectAndReconnect");
      }
    },

  },
  computed: {
    intermediateProcessingStatus() {
      let pipelineStatus = this.$store.state.pipelineConfigs.data.pipelineStatus;
      if(pipelineStatus && pipelineStatus.intermediateProcessingStatus) {
        return pipelineStatus.intermediateProcessingStatus.status;
      } else {
        return null
      }
    },

    isAnySegmentDone() {
      let pipelineStatus = this.$store.state.pipelineConfigs.data.pipelineStatus;
      if(pipelineStatus && pipelineStatus.intermediateProcessingStatus) {
        return !!pipelineStatus.intermediateProcessingStatus.segments.find(a => a.status === "success");
      } else {
        return false
      }
    },

    processingStatus() {
      let pipelineStatus = this.$store.state.pipelineConfigs.data.pipelineStatus;
      if(pipelineStatus && pipelineStatus.processingStatus){
        console.log(pipelineStatus.processingStatus.status)
        return pipelineStatus.processingStatus.status;
      } else {
        return null
      }
    },
    postProcessingStatus() {
      let pipelineStatus = this.$store.state.pipelineConfigs.data.pipelineStatus;
      if(pipelineStatus && pipelineStatus.postProcessingStatus){
        return pipelineStatus.postProcessingStatus.status;
      } else {
        return null
      }
    },
    showHeader() {
      return this.$store.state.events.panels.showHeader;
    },
    showLineChart() {
      return this.$store.state.events.panels.showLineChart;
    },
    showTimeline() {
      const timelineVisibility = this.$store.state.events.panels.showTimeline;
      if(!timelineVisibility) {
        this.$store.commit("SET_TIMELINE_INTERVAL_SELECTION", null);
        this.$store.commit("SET_TIMELINE_BEHAVIOR_SELECTION", null);
      }
      return timelineVisibility;
    },
    showSimilarityPanel() {
      return this.$store.state.events.panels.showSimilarityPanel;
    },
    showAnomalyPanel() {
      return this.$store.state.events.panels.showAnomalyPanel;
    },
    showProgressContainer() {
      return this.$store.state.events.panels.showProgressMonitoring;
    },
  },
  created() {
    this.$store.dispatch("getMode");
    this.$store.dispatch("getAddEnabled");
    this.$store.commit("CLEAR_BLOB_FILES");
      if(this.$store.state.users.currentUser && this.$store.state.users.currentUser.email){
          this.$store.dispatch("getBlobStorageDetails");
          this.$store.dispatch("connectAndReconnect");
      }
  },
  beforeMount() {
    this.width = window.innerWidth;
    window.addEventListener('resize', () => {
      this.width = window.innerWidth
    });
  },
  mounted() {
    this.$store.commit('SHOW_HEADER', true);
  },
  methods: {

    toggleLineChart() {
      if(this.$router.currentRoute.path === '/' && !this.$store.state.videoPlayer.video.isOriginalPlayed){
        this.$store.commit('TOGGLE_LINE_CHART')
      }
    },
    toggleTimeline() {
      if(this.$router.currentRoute.path === '/' && !this.$store.state.videoPlayer.video.isOriginalPlayed){
        this.$store.commit('TOGGLE_TIMELINE');
      }
      if (this.$router.currentRoute.path === '/' && this.$store.state.events.panels.showTimeline === true) {
        this.$store.commit('SET_RESIZE_TIMELINE', true);
      }
    },
    toggleSimilarityPanel() {
      if(this.$router.currentRoute.path === '/' && !this.$store.state.videoPlayer.video.isOriginalPlayed){
        this.$store.commit('TOGGLE_SIMILARITY_PANEL');
      }
    },
    toggleAnomalyPanel() {
      if(this.$router.currentRoute.path === '/' && !this.$store.state.videoPlayer.video.isOriginalPlayed){
        this.$store.commit('TOGGLE_ANOMALY_PANEL');
      }
    },
    editPipeline() {
      this.$router.push(`/manageaoi/pipeline/${this.$store.state.videoPlayer.video.id}/settings`)
    },

    getVideoOnDemand() {
      this.$store.dispatch('getVideoOnDemand', this.$store.state.videoPlayer.video.id)
    },

    refreshPartialVideoFlag(event) {
        const payload = {
          id: this.$store.state.videoPlayer.video.id,
          thumbnail: this.$store.state.videoPlayer.video.thumbnailUrl,
          percentage: this.$store.state.videoPlayer.video.playlistCompletedPercentage
        };

        Promise.all([
          this.$store.dispatch('getPipelineConfig', payload),
        ]).then(() => {
          if(this.$store.state.videoPlayer.video.analysisSrc){
            this.$store.commit('REFRESH_ANALYSIS_SRC');
          }
          this.$store.commit('TOGGLE_PARTIAL_VIDEO_EVENT', false);
        });
      this.$store.dispatch('reloadAllVideoAnalytics', this.$router.currentRoute.query.analyzeId)
    },
    toggleVideoSrc() {
      this.$store.commit("PAUSE_VIDEO", 0);

      if(!this.$store.state.videoPlayer.video.isOriginalPlayed) {
        this.$store.commit("SHOW_ALL_PANELS", false);
      }

      this.$store.commit('TOGGLE_VIDEO_SRC');
      if(!this.$store.state.videoPlayer.video.isOriginalPlayed && this.$store.state.videoPlayer.partialPlaylistEvent ) {
        this.refreshPartialVideoFlag();
      }
     },
    goToManageVideos() {
      if(this.$router.currentRoute.path !== '/manageaoi')
        this.$router.push('/manageaoi');
    },
    goToSettings() {
      // if(!this.$store.state.settings.isPreviewMode() && this.$store.state.users.currentUser && this.$store.state.users.currentUser.isAdmin) {
          this.$router.push('/settings')
      // }
    },
    goToUsers(){
        this.$router.push('/users')
    },
    editProfile() {
        setTimeout(() => {
          this.$refs.userModal.open();
        }, 0);
    },
    changeUserPassword() {
      setTimeout(() => {
        this.$refs.changePasswordModal.open();
      }, 0);
    },
    logout() {
      this.$store.dispatch("handleLogout")
    },

    onShowProgressVizualisationClick(event) {
      if(event.target.id === 'refreshBadge' || event.target.parentElement.id === 'refreshBadge' || event.target.parentElement.parentElement.id === 'refreshBadge' || event.target.parentElement.parentElement.parentElement.id === 'refreshBadge')
          return;

      this.$store.commit('TOGGLE_PROGRESS_MONITORING');
      this.$root.$emit('bv::hide::tooltip');
      if(this.showProgressContainer && !this.$store.state.videoPlayer.totalVideoDuration) {
        const payload = {
          id: this.$store.state.videoPlayer.video.id,
          thumbnail: this.$store.state.videoPlayer.video.thumbnailUrl,
          percentage: this.$store.state.videoPlayer.video.playlistCompletedPercentage
        };
        this.$store.dispatch('getPipelineConfig', payload);
      }
    },
    closeProgressVizualisationClick() {
      this.$store.commit('SHOW_PROGRESS_MONITORING', false);
      this.$root.$emit('bv::hide::tooltip');
    }
  }

}
</script>

<style lang="scss">
@import "./public/assets/css/nash-theme.scss";

.nav-b-button{
  padding: 0!important;
  background-color: inherit!important;
  border: none!important;
  .b-icon{
    font-size: 100%!important;
    vertical-align: text-top!important;
  }
  &:disabled {
    .b-icon{
      cursor: initial;
    }
  }
}
.nav-b-button:focus{
  border: none!important;
  box-shadow: none!important;
}
.nav-b-button:disabled{
  border: none!important;
  box-shadow: none!important;
  color: #6c757d!important;
}
.vizualization-wrapper {
  position: fixed;
  top: 60px;
  font-size: 10px;
  color: #CED6E0;
  border-radius: 5px;
  left: 20%;
  right: 20%;
  z-index: 210;
  height: 150px;
}
.pipeline-info-tooltip{
  opacity: 1!important;
  .tooltip-inner{
    max-width: unset;
    width: 300px;
    background-color: #1B242E!important;
    border-radius: 10px;
  }

}
.header {
  /* To keep header 100% width. */
  min-height: 50px;
  height: 52px;
  /* background-color: #3e8ede; */
  background-color: rgba(57, 60, 67, 0.9);
  color: #e1ebf5;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 100;
  opacity: 0.9;
  position: absolute;
  top: 0px;
  left: 0;
  z-index: 100;
  width: 100%;
  display: flex;
  flex-direction: row;
  box-shadow: 0px 3px 1px rgba(0, 0, 0, 0.4);

  .header-left {
    padding-left: 10px;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .header-right {
    padding-right: 20px;
    padding-left: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .pressedicon {
      color: #0A78CC!important;
    }
    .user-name {
      font-size: 12px;
      margin-top: 2px;
      align-self: center;
      cursor: pointer;
      .dropdown-menu{
        min-width: unset;
        font-size: 12px;
        width: 150px;
        background-color: rgba(57, 60, 67, 1);
        .dropdown-divider{
          width: 80%;
          margin-left: 15px;
          border-color: #e1ebf5;
         }
        .dropdown-item{
          .dropdown-item-row{
            width: 100%;
            height: 100%;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
            margin-left: 15px!important;
            svg {
              margin-right: 5px;
            }
          }
          padding: 0;
          height: 30px;
          color: #e1ebf5;

        }
        .dropdown-item:hover{
          background-color: rgba(225, 235, 245, .1) !important;
        }
      }
      .open{
        color: #0A78CC!important;
      }

      > button {
        background: none;
        border: none;
        padding: 0;
        font-size: inherit;
      }
      >button:focus {
        box-shadow: none!important;
      }
      > button:active {
        color: #0A78CC!important;
        box-shadow: none;
        background-color: inherit;
      }
    }
  }
  .header-center {
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex: 1;
    .file-name {
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: initial;
    }

    .edit-pencil-wrapper{
      padding: 0 10px;
      border-right: solid 1px #6c757d;
      height: 100%;
      display: flex;
      align-items: center;
    }
    .edit-pencil-wrapper:hover{
      background-color: #5a6268!important;
      border-color: #545b62!important;
    }
    .progress-bar-button-name:hover{
      background-color: #5a6268!important;
      border-color: #545b62!important;
    }
    .progress-bar-button-name{
      height: 100%;
      width: 137px;
      display: flex;
      align-items: center;
    }

    .video-name-button {
      text-align: center;
      vertical-align: center;
      line-height: 38px;
      width: 100%;
      height: 100%;
    }
    .video-name-button:hover {
      background-color: #5a6268!important;
      border-color: #545b62!important;
    }


      .progress-bar-button{
      position: relative;
      .progress-bar-button-text {
        width: 100%;
        text-align: center;
      }
      .progress-bar-icon {
        position: absolute;
        left: 92%;
        font-size: 10px;
        top: -5px !important;
      }
    }

    .play-button {
      background-color: transparent!important;
      margin-left: 15px;
    }
    .play-button:hover{
      background-color: #5a6268!important;
      border-color: #545b62!important;
    }
    .split-button:hover{
      background-color: inherit!important;
    }
    .file-name:hover {
      background-color: transparent!important;
    }
  }
  .divider-vertical {
    border-left: 1px solid #e1ebf5;
    height: 25px;
    opacity: 0.6;
    margin-right: 14px!important;
  }

  .nav-button {
    cursor: pointer;
    margin-right: 15px;
    &.disabled-nav-btn{
      color: grey;
      cursor: not-allowed;
    }
  }
}

@media screen and (max-width: 1200px) {
  .vizualization-wrapper {
    top: 85px;
  }
  
  .header {
    flex-direction: column;
    height: 80px;

  }
  .header-left {
    width: 100%;
  }
}
</style>

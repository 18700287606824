<template>
    <div></div>
</template>

<script>
    export default {
        name: "Message",
        props: {},
        data() {
            return {
                /* 0-info | 1-error | 2-warning | 3-success */
                type: 3,
                icons: [
                    "b-icon-info-circle",
                    "b-icon-x-circle",
                    "b-icon-exclamation-circle",
                    "b-icon-check-circle"
                ],
                colors: ["#1d72f3", "#FF526F", "#ffc007", "#2bde3f"],
                classes: ["toast--blue", "toast--red", "toast--yellow", "toast--green"],
                titles: ["Info", "Error", "Warning", "Success"],
                message: "Internal server error. Bad request just occured.",
                errorDelay: 60000,
                shownToasts: [],

            };
        },
        watch: {
            "$store.state.message": function(newval, oldval) {
                this.type = newval.type;
                this.message = newval.message ? newval.message : "Unknown error";
                this.makeToast(newval.errorDelay ? newval.errorDelay : this.errorDelay);
            }
        },
        mounted() {
            this.$root.$on('bv::toast:hidden', event => {
                this.shownToasts = this.shownToasts.filter(toastId => toastId !== event.componentId);
            });
        },
        destroyed() {
            this.$root.$off('bv::toast:hidden');
        },
        methods: {
            makeToast(errorDelay) {
                const createElement = this.$createElement;
                const icon = createElement(this.icons[this.type], {
                    attrs: { name: this.icons[this.type] },
                    class: ["mr-10-1"],
                    style: { fill: this.colors[this.type] }
                });
                const vNodesMsg = createElement(
                    "div",
                    { class: ["content-holder", this.classes[this.type]] },
                    [
                        icon,
                        createElement("div", { class: ["text-container"] }, [
                            createElement("strong", {}, this.titles[this.type]),
                            this.message
                        ])
                    ]
                );

                let delay = errorDelay;
                if(this.type === 3 || this.type === 0) {
                    delay = 3000;
                }
                if(this.shownToasts.length === 0 || !this.shownToasts.find(toastId => toastId === this.message)){
                    this.shownToasts.push(this.message);
                    this.$bvToast.toast([vNodesMsg], {
                        id: this.message,
                        title: [],
                        autoHideDelay: delay,
                        appendToast: true,
                        headerClass: 'custom-toast-header',
                    });
                }

            },
            getType(i) {
                return i === this.type;
            }
        }
    };
</script>

<style lang="scss">
    .toast--green:before {
        background-color: #2bde3f;
    }

    .toast--blue:before {
        background-color: #1d72f3;
    }

    .toast--yellow:before {
        background-color: #ffc007;
    }

    .toast--red:before {
        background-color: #ff4444;
    }

    .custom-toast-header {
        padding: 0px !important;
        background-color: transparent !important;
        border: none !important;
        width: 100%;
        box-shadow: none;
        button{
            padding: 0 5px!important;
            border: solid thin #1d72f3!important;
            color: #1d72f3!important;
            margin-right: 5px;
        }
        button:focus {
            outline: none;
        }
    }

    .toast-body {
        background-color: white;
        padding: 0px;
        .content-holder {
            padding: 14px 41px 14px 5px;
            display: flex;
            flex-direction: row;
            align-items: center;
            text-align: left;
            svg {
                margin-right: 10px;
            }
            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 6px;
                height: 100%;
            }
            .text-container {
                width: 80%;
                word-break: break-word;
                display: flex;
                flex-direction: column;
            }
        }
    }
</style>
